"use client";
import React, { useEffect, useMemo } from "react";
import { usePropertyContext } from "./context";
import PropertyModal from "./Modal";
import { usePropertyEquityContext } from "./PropertyEquityContext";
import { Checkbox, Label, Select, TextField } from "elements/Input";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import { loanTermOptions } from "./options";
import moment from "moment";
import client from "utils/client";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { useAuthContext } from "context/AuthContext";
import Image from "next/image";

import DoubleCheckmarkAnimation from "public/account/double-checkmark.gif";
import LockIcon from "public/lock.svg";
import { useSearchParams } from "next/navigation";
import ButtonV2 from "elements/ButtonV2";
import EquityHouse from "public/equity-house.svg";
import HouseIllustrationMortgagePaidOff from "public/house-illustration-mortgage-paid-off.svg";
import { displayNullableDollarField } from "utils/account";
import { usePropertyValueContext } from "./PropertyValueChart";
import formatNumberWithCommas from "utils/formatNumberWithCommas";
import HouseWithCoinsSkyLightBGSVG from "public/house-with-coins-sky-light-bg.svg";
import QuickLink from "elements/QuickLink";
import { RefinanceMonitoring } from "./RefinanceMonitoring";
import { PropertyEquity } from "utils/types";

export const submit_equity_details = async ({
	property_id,
	purchase_date,
	purchase_price,
	loan_amount,
	loan_interest_rate,
	loan_term,
	mortgage_paid_off,
	extra_monthly_contribution,
	refinance_date,
}: {
	property_id: number;
	purchase_date: string | null;
	purchase_price: string | null;
	loan_amount: string | null;
	loan_interest_rate: string | null;
	loan_term: string | null;
	mortgage_paid_off: boolean;
	extra_monthly_contribution: string | null;
	refinance_date: string | null;
}) => {
	const payload: Parameters<typeof client.patchEquityDetails>[0]["payload"] =
		{};

	const isNotNullOrEmptyString = (value: string | null): value is string =>
		value !== null && value !== "";

	if (isNotNullOrEmptyString(purchase_date))
		payload.last_sale_date_prop = moment(purchase_date).format("YYYY-MM-DD");
	if (isNotNullOrEmptyString(purchase_price))
		payload.last_sale_price_prop = parseInt(purchase_price.replace(/\D/g, ""));
	if (isNotNullOrEmptyString(loan_amount))
		payload.mortgage_amount = parseInt(loan_amount.replace(/\D/g, ""));
	if (isNotNullOrEmptyString(loan_interest_rate))
		payload.mortgage_interest_rate = parseFloat(loan_interest_rate);
	if (isNotNullOrEmptyString(loan_term))
		payload.mortgage_term = parseInt(loan_term.replace(/\D/g, ""));
	if (mortgage_paid_off !== null) payload.mortgage_paid_off = mortgage_paid_off;

	if (
		isNotNullOrEmptyString(extra_monthly_contribution) &&
		extra_monthly_contribution !== "$"
	) {
		const amount = parseInt(extra_monthly_contribution.replace(/\D/g, "")) || 0;
		if (amount > 0) {
			payload.mortgage_extra_monthly_payment = amount;
		}
	} else {
		payload.mortgage_extra_monthly_payment = 0;
	}

	if (isNotNullOrEmptyString(refinance_date))
		payload.mortgage_refinance_date =
			moment(refinance_date).format("YYYY-MM-DD");

	if (!refinance_date) {
		payload.mortgage_refinance_date = null;
	}

	await client.patchEquityDetails({
		property_id,
		payload,
	});
};

export const EquityFieldsContext = React.createContext<{
	purchaseDate: string | null;
	setPurchaseDate: (date: string | null) => void;
	purchasePrice: string | null;
	setPurchasePrice: (price: string | null) => void;
	loanAmount: string | null;
	setLoanAmount: (amount: string | null) => void;
	loanInterestRate: string | null;
	setLoanInterestRate: (rate: string | null) => void;
	loanTerm: string | null;
	setLoanTerm: (term: string | null) => void;
	mortgagePaidOff: boolean;
	setMortgagePaidOff: (paidOff: boolean) => void;
	mortgageRefinanced: boolean;
	setMortgageRefinanced: (refinanced: boolean) => void;
	mortgageRefinanceDate: string | null;
	setMortgageRefinanceDate: (date: string | null) => void;
	extraMonthlyContribution: string | null;
	setExtraMonthlyContribution: (contribution: string | null) => void;
	submitEquityDetails: () => void;
	allFieldsFilled: boolean;
	patchSubmitting: boolean;
	patchSubmitted: boolean;
	patchError: boolean;
}>({
	purchaseDate: null,
	setPurchaseDate: (date: string | null) => {},
	purchasePrice: null,
	setPurchasePrice: (price: string | null) => {},
	loanAmount: null,
	setLoanAmount: (amount: string | null) => {},
	loanInterestRate: null,
	setLoanInterestRate: (rate: string | null) => {},
	loanTerm: null,
	setLoanTerm: (term: string | null) => {},
	mortgagePaidOff: false,
	setMortgagePaidOff: (paidOff: boolean) => {},
	mortgageRefinanced: false,
	setMortgageRefinanced: (refinanced: boolean) => {},
	mortgageRefinanceDate: null,
	setMortgageRefinanceDate: (date: string | null) => {},
	extraMonthlyContribution: null,
	setExtraMonthlyContribution: (contribution: string | null) => {},
	submitEquityDetails: () => {},
	allFieldsFilled: false,
	patchSubmitting: false,
	patchSubmitted: false,
	patchError: false,
});

export const EquityFieldsProvider: React.FC<{
	preselections?: {
		mortgage_paid_off?: boolean;
	};
}> = ({ preselections, children }) => {
	const { details } = usePropertyContext();

	const {
		mortgage_paid_off,
		mortgage_refinance_date,
		mortgage_amount,
		mortgage_term,
		mortgage_interest_rate,
		last_sale_date_prop,
		last_sale_price_prop,
	} = usePropertyEquityContext();

	const [purchaseDate, setPurchaseDate] = React.useState<string | null>(null);
	const [purchasePrice, setPurchasePrice] = React.useState<string | null>(null);
	const [loanAmount, setLoanAmount] = React.useState<string | null>(null);
	const [loanInterestRate, setLoanInterestRate] = React.useState<string | null>(
		null
	);
	const [loanTerm, setLoanTerm] = React.useState<string | null>(null);
	const [mortgagePaidOff, setMortgagePaidOff] = React.useState<boolean>(
		preselections?.mortgage_paid_off ?? !!mortgage_paid_off
	);
	const [mortgageRefinanced, setMortgageRefinanced] = React.useState<boolean>(
		!!mortgage_refinance_date
	);
	const [mortgageRefinanceDate, setMortgageRefinanceDate] = React.useState<
		string | null
	>(null);
	const [extraMonthlyContribution, setExtraMonthlyContribution] =
		React.useState<string | null>(null);

	const [patchError, setPatchError] = React.useState(false);
	const [patchSubmitting, setPatchSubmitting] = React.useState(false);
	// Using this value to determine if the user has save changes since opening the modal
	const [patchSubmitted, setPatchSubmitted] = React.useState(false);

	const submitEquityDetails = async () => {
		if (!details?.id) return;
		try {
			setPatchError(false);
			setPatchSubmitting(true);
			await submit_equity_details({
				property_id: details.id,
				purchase_date: purchaseDate,
				purchase_price: purchasePrice,
				loan_amount: loanAmount,
				loan_interest_rate: loanInterestRate,
				loan_term: loanTerm,
				mortgage_paid_off: mortgagePaidOff,
				extra_monthly_contribution: extraMonthlyContribution,
				refinance_date: mortgageRefinanceDate,
			});
			setPatchSubmitted(true);
			setPatchSubmitting(false);
		} catch (e) {
			console.error(e);
			setPatchError(true);
		}
	};

	const allFieldsFilled = useMemo(() => {
		if (!!mortgagePaidOff) {
			return true;
		}

		if (!!mortgageRefinanced) {
			return [
				mortgageRefinanceDate ?? mortgage_refinance_date,
				purchasePrice ?? last_sale_price_prop,
				loanAmount ?? mortgage_amount,
				loanInterestRate ?? mortgage_interest_rate,
				loanTerm ?? mortgage_term,
			].every(field => !!field);
		}

		return [
			purchaseDate ?? last_sale_date_prop,
			purchasePrice ?? last_sale_price_prop,
			loanAmount ?? mortgage_amount,
			loanInterestRate ?? mortgage_interest_rate,
			loanTerm ?? mortgage_term,
		].every(field => !!field);
	}, [
		last_sale_date_prop,
		last_sale_price_prop,
		loanAmount,
		loanInterestRate,
		loanTerm,
		mortgage_amount,
		mortgage_interest_rate,
		mortgage_term,
		purchaseDate,
		purchasePrice,
		mortgagePaidOff,
	]);

	return (
		<EquityFieldsContext.Provider
			value={{
				purchaseDate,
				setPurchaseDate,
				purchasePrice,
				setPurchasePrice,
				loanAmount,
				setLoanAmount,
				loanInterestRate,
				setLoanInterestRate,
				loanTerm,
				setLoanTerm,
				mortgagePaidOff,
				setMortgagePaidOff,
				mortgageRefinanced,
				setMortgageRefinanced,
				mortgageRefinanceDate,
				setMortgageRefinanceDate,
				extraMonthlyContribution,
				setExtraMonthlyContribution,
				submitEquityDetails,
				allFieldsFilled,
				patchSubmitting,
				patchSubmitted,
				patchError,
			}}>
			{children}
		</EquityFieldsContext.Provider>
	);
};

export const useEquityFields = () => React.useContext(EquityFieldsContext);

export const EquityFields = () => {
	const {
		mortgageRefinanced,
		setMortgageRefinanced,
		mortgagePaidOff,
		setMortgagePaidOff,
		purchaseDate,
		setPurchaseDate,
		mortgageRefinanceDate,
		setMortgageRefinanceDate,
		purchasePrice,
		setPurchasePrice,
		loanAmount,
		setLoanAmount,
		loanInterestRate,
		setLoanInterestRate,
		loanTerm,
		setLoanTerm,
		extraMonthlyContribution,
		setExtraMonthlyContribution,
	} = useEquityFields();

	const {
		last_sale_date_prop,
		last_sale_price_prop,
		mortgage_refinance_date,
		mortgage_amount,
		mortgage_interest_rate,
		mortgage_term,
		mortgage_extra_monthly_payment,
	} = usePropertyEquityContext();

	return (
		<>
			{!mortgageRefinanced && (
				<>
					<Checkbox
						checked={mortgagePaidOff}
						onChange={e => {
							setMortgagePaidOff(e.target.checked);
							setExtraMonthlyContribution(null);
						}}
						label="My mortgage is paid off."
						labelEl="p.sm"
					/>
					{!!mortgagePaidOff && (
						<div className="property-equity-details-modal-mortgage-paid-off-box">
							<p className="lg bold">Yay! Your mortgage is paid off 🎉</p>
						</div>
					)}
				</>
			)}

			{!mortgagePaidOff && (
				<Checkbox
					checked={mortgageRefinanced}
					onChange={e => {
						setMortgageRefinanced(e.target.checked);
					}}
					label="I've refinanced since original purchase."
					labelEl="p.sm"
				/>
			)}

			{!mortgageRefinanced && (
				<TextField
					label="Purchase Date"
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={
						purchaseDate !== null ? purchaseDate : last_sale_date_prop || ""
					}
					onChange={e => {
						const formatted = formatDate(e, purchaseDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setPurchaseDate(formatted);
						}
					}}
				/>
			)}

			{mortgageRefinanced && (
				<TextField
					label="Refinance Date"
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={
						mortgageRefinanceDate !== null
							? mortgageRefinanceDate
							: mortgage_refinance_date || ""
					}
					onChange={e => {
						const formatted = formatDate(e, mortgageRefinanceDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setMortgageRefinanceDate(formatted);
						}
					}}
				/>
			)}
			<TextField
				label={
					mortgageRefinanced ? "Original Purchase Amount" : "Purchase Amount"
				}
				placeholder="$"
				value={
					purchasePrice !== null
						? purchasePrice
						: formatDollarStr(last_sale_price_prop || "")
				}
				onChange={e => {
					setPurchasePrice(formatDollarStr(e.target.value) || "");
				}}
			/>
			{!mortgagePaidOff && (
				<>
					<TextField
						label={
							mortgageRefinanced
								? "Refinanced Loan Amount"
								: "Mortgage Loan Amount"
						}
						placeholder="$"
						value={
							loanAmount !== null
								? loanAmount
								: formatDollarStr(mortgage_amount || "")
						}
						onChange={e => {
							setLoanAmount(formatDollarStr(e.target.value) || "");
						}}
					/>
					<TextField
						label={
							mortgageRefinanced ? "Refinanced Interest Rate" : "Interest Rate"
						}
						labelEl={({ children }) => (
							<Label className="input-label">
								{children}
								<sup>1</sup>
							</Label>
						)}
						placeholder="%"
						containerClassName="percentage-input"
						value={
							loanInterestRate !== null
								? loanInterestRate
								: mortgage_interest_rate || ""
						}
						maxLength={6}
						onChange={e => {
							if (e.target.value === "") {
								setLoanInterestRate("");
							}
							if (/^[0-9]+(\.\d*)?$/g.test(e.target.value)) {
								setLoanInterestRate(e.target.value);
							}
						}}
					/>
					<Select
						label="Loan Term"
						value={loanTerm !== null ? loanTerm : (mortgage_term ?? "")}
						onChange={e => setLoanTerm(e.target.value)}>
						<option hidden disabled value="">
							Select Loan Term
						</option>
						{loanTermOptions(mortgage_term)}
					</Select>
					<Checkbox
						checked={
							extraMonthlyContribution !== null
								? !!extraMonthlyContribution
								: !!mortgage_extra_monthly_payment
						}
						disabled={!!mortgagePaidOff}
						onChange={e => {
							if (e.target.checked) {
								setExtraMonthlyContribution("$");
							} else {
								setExtraMonthlyContribution("");
							}
						}}
						label="I contribute extra to the principal balance."
						labelEl="p.sm"
					/>
					{(extraMonthlyContribution !== null
						? !!extraMonthlyContribution
						: !!mortgage_extra_monthly_payment) && (
						<TextField
							label="Monthly Principal Contribution"
							value={
								extraMonthlyContribution !== null
									? formatDollarStr(extraMonthlyContribution) || "$"
									: mortgage_extra_monthly_payment
										? formatDollarStr(mortgage_extra_monthly_payment)
										: "$"
							}
							onChange={e => {
								setExtraMonthlyContribution(
									formatDollarStr(e.target.value) || "$"
								);
							}}
						/>
					)}
				</>
			)}
		</>
	);
};

const EditEquityDetailsModalHeader = () => {
	const { patchSubmitted, mortgagePaidOff } = useEquityFields();

	const { details } = usePropertyContext();

	if (patchSubmitted && mortgagePaidOff) {
		return (
			<div>
				<p className="no-translate lg bold text-center">Congratulations</p>
			</div>
		);
	}

	return (
		<div>
			<p className="no-translate lg bold text-center">
				{details?.address} {details?.city}
				{", "}
				{details?.state} {details?.zip_code}
			</p>
		</div>
	);
};

export const EditEquityDetailsFormContent: React.FC<
	{
		data_incomplete: boolean;
	} & Pick<
		PropertyEquity.Response,
		| "mortgage_last_updated_at"
		| "equity"
		| "debt"
		| "equity"
		| "mortgage_paid_off"
	>
> = ({
	children,
	data_incomplete,
	debt,
	equity,
	mortgage_last_updated_at,
	mortgage_paid_off,
}) => {
	return (
		<div id="property-equity-details-content">
			<div id="property-equity-details-form">
				<p className="form-title lg bold">Edit Mortgage Details</p>
				{data_incomplete && (
					<p className="body-tiny denim_5">
						Data incomplete. Please edit the information for more accurate
						results.
					</p>
				)}
				{!data_incomplete && mortgage_last_updated_at && (
					<p className="body-tiny denim_5">
						Last edited by you{" "}
						<span className="no-translate">
							{moment(mortgage_last_updated_at).format("MM/DD/YY")}
						</span>
					</p>
				)}
				<fieldset>
					<div className={"property-equity-details-form-fields"}>
						{children}
					</div>
				</fieldset>
			</div>
			<div id="property-equity-details-breakdown">
				<EquityHouse className="equity-house" />
				<div className="mortgage-card-equity-debt-details">
					<div className="mortgage-card-equity-debt-detail-box">
						<p className="lg bold">Equity</p>
						<p className="bold azure flex items-center no-translate">
							{!data_incomplete ? displayNullableDollarField(equity) : "--"}
						</p>
					</div>
					<div className="mortgage-card-equity-debt-detail-box">
						<p className="lg bold">Debt</p>
						{(!!mortgage_paid_off || debt === 0) && (
							<p className="bold rust">🎉 Paid Off</p>
						)}
						{debt !== 0 && (
							<p className="bold rust no-translate">
								{!data_incomplete ? displayNullableDollarField(debt) : "--"}
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export const EditEquityDetailsForm = ({
	onAfterSubmit,
}: {
	onAfterSubmit?: () => void;
}) => {
	const { userData } = useAuthContext();

	const { details } = usePropertyContext();

	const {
		mortgage_last_updated_at,
		mortgage_paid_off,
		last_sale_date_prop,
		last_sale_price_prop,
		mortgage_loan_type,
		mortgage_financing_type,
		mortgage_interest_rate,
		mortgage_term,
		mortgage_amount,
		dataIncomplete,
		userCameFrom,
		setUserCameFrom,
		equity,
		debt,
		refreshPropertyEquityDetails,
	} = usePropertyEquityContext();

	const { roiSincePurchase } = usePropertyValueContext();

	const trackEvent = useEventTracker();

	const { allFieldsFilled, submitEquityDetails } = useEquityFields();

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				submitEquityDetails();
				trackEvent({
					eventName: constants.EVENTS.Equity_New_Data_Saved,
					data: {
						"Customer ID": userData?.id,
						"Sign Up Date": userData?.created_at,
						"Number of Properties": userData?.num_properties,
						"Property ID": details?.id,
						last_sale_date_prop,
						last_sale_price_prop,
						mortgage_loan_type,
						mortgage_financing_type,
						mortgage_interest_rate,
						mortgage_term,
						mortgage_amount,
						"User Came From": userCameFrom,
					},
				});

				refreshPropertyEquityDetails();
				onAfterSubmit?.();
			}}>
			<EditEquityDetailsFormContent
				mortgage_paid_off={mortgage_paid_off}
				data_incomplete={dataIncomplete}
				debt={debt}
				equity={equity}
				mortgage_last_updated_at={mortgage_last_updated_at}>
				<EquityFields />
			</EditEquityDetailsFormContent>
			<footer id="property-equity-details-footer">
				<ButtonV2
					className="mx-auto"
					size="mobile-extra-small"
					type="submit"
					disabled={!allFieldsFilled}>
					Save Changes
				</ButtonV2>
			</footer>
		</form>
	);
};

const EditEquityDetailsConfirmation = ({
	onAttemptExit,
}: {
	onAttemptExit: () => void;
}) => {
	const { mortgagePaidOff } = useEquityFields();
	return (
		<div>
			<div id="property-equity-details-modal-confirmation-content">
				{!mortgagePaidOff && (
					<div>
						<div className="flex justify-center">
							<Image
								width={150}
								height={150}
								src={DoubleCheckmarkAnimation}
								alt="Checkmark animation"
							/>
						</div>
						<p className="sm text-center">
							Your mortgage and equity monitoring is now active.
						</p>
						<div className="mt-2 flex justify-center">
							<LockIcon className="lock-icon" />
							<p className="sm bold denim_5">
								Your data security is important to us!
							</p>
						</div>
					</div>
				)}
				{!!mortgagePaidOff && (
					<>
						<HouseIllustrationMortgagePaidOff className="mx-auto house-illustration-mortgage-paid-off-graphic" />
						<p className="denim-medium lg text-center">
							Even though you don't have a mortgage on this property, you can
							still track your Equity, ROI and other useful features.
						</p>
					</>
				)}
			</div>
			<footer id="property-equity-details-modal-footer">
				<ButtonV2
					size="extra-small"
					className="mx-auto"
					onClick={onAttemptExit}>
					Continue
				</ButtonV2>
			</footer>
		</div>
	);
};

const TurnOnRefinanceMonitoring = ({
	onAttemptExit,
}: {
	onAttemptExit: () => void;
}) => {
	const { userData } = useAuthContext();
	const { details, setModalToShow } = usePropertyContext();
	const refinanceMonitoringContext = RefinanceMonitoring.useContext();
	const refinanceMonitoringSurveyComplete =
		!!refinanceMonitoringContext?.survey_values?.refinance_monitoring
			?.completed_survey;

	const { skip_intro } = RefinanceMonitoring.Survey.useFlow();

	const trackEvent = useEventTracker();

	return (
		<div>
			<div
				id="property-equity-details-modal-turn-on-refinance-monitoring-content"
				className="space-y-2">
				<p className="h4 bold text-center">
					Are you overpaying on your mortgage?
				</p>
				<HouseWithCoinsSkyLightBGSVG
					style={{ minWidth: "200px", maxHeight: "175px" }}
				/>
				<div className="space-y-1">
					<p className="h5 bold text-center">How refinance monitoring works:</p>
					<ul>
						<li className="mx-8 denim-medium">
							Free regular rate monitoring, so you don't have to
						</li>
						<li className="mx-8 denim-medium">
							We'll let you know if there are any savings you could take
							advantage of
						</li>
						<li className="mx-8 denim-medium">
							Easy to understand breakdown of how you compare
						</li>
						<li className="mx-8 denim-medium">
							Access to our refinancing calculator
						</li>
					</ul>
				</div>
			</div>
			<footer id="property-equity-details-modal-footer">
				<ButtonV2
					size="extra-small"
					className="mx-auto"
					onClick={() => {
						trackEvent({
							eventName: refinanceMonitoringSurveyComplete
								? constants.EVENTS
										.Refinance_Monitoring_Review_From_Equity_Details
								: constants.EVENTS
										.Refinance_Monitoring_Start_From_Equity_Details,
							data: {
								"Customer ID": userData?.id,
								"Property ID": details?.id,
							},
						});
						skip_intro?.();
						setModalToShow("refinance-monitoring-survey-flow");
					}}>
					{refinanceMonitoringSurveyComplete
						? "Update Refinance Monitoring"
						: "Continue"}
				</ButtonV2>
				{!refinanceMonitoringSurveyComplete && (
					<QuickLink
						size="small"
						asButton
						onClick={() => {
							trackEvent({
								eventName:
									constants.EVENTS
										.Refinance_Monitoring_Dont_Notify_Me_About_Savings,
								data: {
									"Customer ID": userData?.id,
									"Property ID": details?.id,
								},
							});
							onAttemptExit();
						}}
						className="mx-auto mt-2">
						Don't notify me about savings
					</QuickLink>
				)}
			</footer>
		</div>
	);
};

export const EditEquityDetailsModal = ({
	preselections,
}: {
	preselections?: {
		mortgage_paid_off?: boolean;
	};
}) => {
	const { userData } = useAuthContext();

	const trackEvent = useEventTracker();
	const searchParams = useSearchParams();

	const { details, setModalToShow, fetchPropertyDetails } =
		usePropertyContext();

	const { setUserCameFrom } = usePropertyEquityContext();

	return (
		<EquityFieldsProvider preselections={preselections}>
			<EquityFieldsContext.Consumer>
				{({ patchSubmitted, mortgagePaidOff }) => {
					const onAttemptExit = () => {
						setModalToShow(undefined);
						setUserCameFrom(undefined);
						if (!!searchParams.get("onboarding")) {
							trackEvent({
								eventName: constants.EVENTS.Onboarding_mortgage_equity_exit,
								data: {
									"Customer ID": userData?.id,
									"Property ID": details?.id,
								},
							});
						}
						if (patchSubmitted && details?.id) {
							fetchPropertyDetails(details.id.toString(), {
								autoOpenNARModal: false,
							});
						}
					};

					return (
						<PropertyModal
							clickAway
							onAttemptExit={onAttemptExit}
							modalId="property-equity-details-modal"
							header={<EditEquityDetailsModalHeader />}>
							{patchSubmitted ? (
								mortgagePaidOff ? (
									<EditEquityDetailsConfirmation
										onAttemptExit={onAttemptExit}
									/>
								) : (
									<TurnOnRefinanceMonitoring onAttemptExit={onAttemptExit} />
								)
							) : (
								<EditEquityDetailsForm />
							)}
						</PropertyModal>
					);
				}}
			</EquityFieldsContext.Consumer>
		</EquityFieldsProvider>
	);
};
