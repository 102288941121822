import ButtonV2 from "elements/ButtonV2";
import CircleExclaim from "public/account/survey/concierge/circle-exclaim.svg";
import { Tooltip3 } from "elements/Tooltip";

const NoConciergeOptionCard = ({
	contingencyPercentage,
	onOptionSelect,
}: {
	contingencyPercentage: number;
	onOptionSelect: () => void;
}) => {
	return (
		<div className="concierge-options-card basic-option">
			<div className="card-header">
				<p className="card-heading">Basic</p>
			</div>
			<div className="pricing">
				<p className="price h4 denim">Included</p>
				<div className="contingency-box">
					<p className="xl denim-medium">25%</p>
					<Tooltip3
						className="contingency-tooltip"
						text="Our savings-or-free contingency fee is applied to bill savings we achieve for you. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250.">
						contingency fee
					</Tooltip3>
				</div>
			</div>
			<div className="cta">
				<ButtonV2
					variant="primary-outline"
					size="extra-small"
					className="w-100"
					onClick={onOptionSelect}>
					Stay With Basic Plan
				</ButtonV2>
			</div>
			<div className="info">
				<p className="description xs">
					For individuals who only want tax appeal representation and track
					their taxes online.
				</p>
			</div>
			<div className="features">
				<p className="feature-heading">What's Included?</p>
				<div className="feature-list">
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Property Tax Dashboard</span>
					</p>
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Annual Appeals</span>
					</p>
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Tax Appeal Support</span>
					</p>
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Exemption filling</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default NoConciergeOptionCard;
