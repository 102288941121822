import ButtonV2 from "elements/ButtonV2";
import { Tooltip3 } from "elements/Tooltip";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import CircleExclaim from "public/account/survey/concierge/circle-exclaim.svg";
import ShieldCheck from "public/account/survey/concierge/shield-check.svg";

const ConciergeOptionCard = ({
	billingCycle,
	contingencyPercentage,
	onOptionSelect,
}: {
	billingCycle: string;
	contingencyPercentage: number;
	onOptionSelect: (option: string) => void;
}) => {
	return (
		<div className="concierge-options-card concierge-option">
			<div className="card-header">
				<p className="card-heading">Concierge</p>
				<p className="most-popular xs semibold">Most Popular ✨</p>
			</div>
			<div className="pricing">
				<p className="price h4 azure font-40">
					$99
					<sub>
						/year <strong>(or $10/month)</strong>
					</sub>
				</p>
				<div className="contingency-box">
					<p className="xl denim-medium">25%</p>
					<Tooltip3
						className="contingency-tooltip"
						text="Our savings-or-free contingency fee is applied to bill savings we achieve for you. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250.">
						contingency fee
					</Tooltip3>
				</div>
			</div>
			<div className="cta">
				<ButtonV2
					variant="primary"
					size="extra-small"
					className="w-100"
					onClick={() => onOptionSelect("concierge")}>
					Upgrade To Concierge
				</ButtonV2>
			</div>
			<div className="info">
				<p className="description xs">
					For households who want to reduce all the expenses associated with
					their property. Customers on average save over $1,100 per year.
				</p>
			</div>
			<SavingsBox />
			<ConciergeFeatures />
		</div>
	);
};

export const ConciergeFeatures = () => {
	return (
		<div className="features">
			<p className="feature-heading">What's Included?</p>
			<div className="feature-list">
				<p className="pre-requisite">Everything in Basic Plan +</p>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Mortgage Reduction</span>
					</p>
					<p className="list-item-value">$2,542 avg savings</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Insurance Reduction</span>
					</p>
					<p className="list-item-value">$439 avg savings</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Utility Reduction</span>
					</p>
					<p className="list-item-value">$498 avg savings</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Internet Bill Reduction</span>
					</p>
					<p className="list-item-value">$233 avg savings</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Unclaimed Property</span>
					</p>
					<p className="list-item-value">$532 avg savings</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Trust Creation </span>{" "}
						<span className="subtext">(optional)</span>
					</p>
					<p className="list-item-value">$500+ value</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Local reports </span>{" "}
						<span className="subtext">(home values, crime, etc.)</span>
					</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Tax Payments </span>{" "}
						<span className="subtext">(non-escrow)</span>
					</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>White Glove Onboarding</span>
					</p>
				</div>
			</div>
			{/* <div className="results">
				<p className="list-item-label tiny semibold">Est. Savings</p>
				<p className="list-item-value tiny semibold">$4,320 avg savings</p>
			</div> */}
		</div>
	);
};

export const SavingsBox = () => {
	return (
		<div className="saving">
			<ShieldCheck className="shield-icon" />
			<div className="savings-content">
				<p className="savings-header xs semibold">Savings Guarantee</p>
				<p className="savings-text tiny">
					We’ll save you more than our yearly fee, or your money back!
				</p>
			</div>
		</div>
	);
};

export default ConciergeOptionCard;
