import { useConciergeContext } from "./ConciergePage";

const ConciergeOptionTab = () => {
	const { billingCycle, setBillingCycle } = useConciergeContext();

	return (
		<div className="concierge-options-tab">
			<p className="tab-label xs">Billing Period</p>
			<div className="tab-option">
				<p
					className={
						"small tab " + (billingCycle == "annually" ? "active" : "")
					}
					onClick={() => setBillingCycle("annually")}>
					Annually (save 15%)
				</p>
				<p
					className={"small tab " + (billingCycle == "monthly" ? "active" : "")}
					onClick={() => setBillingCycle("monthly")}>
					Monthly
				</p>
			</div>
		</div>
	);
};

export default ConciergeOptionTab;
