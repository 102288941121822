import ButtonV2 from "elements/ButtonV2";
import { Tooltip3 } from "elements/Tooltip";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import CircleExclaim from "public/account/survey/concierge/circle-exclaim.svg";
import ShieldCheck from "public/account/survey/concierge/shield-check.svg";

const ConciergeProOptionCard = ({
	billingCycle,
	contingencyPercentage,
	onOptionSelect,
}: {
	billingCycle: string;
	contingencyPercentage: number;
	onOptionSelect: (option: string) => void;
}) => {
	return (
		<div className="concierge-options-card concierge-pro-option">
			<div className="card-header">
				<p className="card-heading">Concierge Pro</p>
			</div>
			<div className="pricing">
				<p className="price h4">
					$510
					<sub>
						/year <strong>(or $50/month)</strong>
					</sub>
				</p>
				<div className="contingency-box">
					<p className="xl denim-medium">25%</p>
					<Tooltip3
						className="contingency-tooltip"
						text="Our savings-or-free contingency fee is applied to bill savings we achieve for you. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250.">
						contingency fee
					</Tooltip3>
				</div>
			</div>
			<div className="cta">
				<ButtonV2
					variant="primary-outline"
					size="extra-small"
					className="w-100"
					onClick={() => onOptionSelect("concierge-pro")}>
					Upgrade To Concierge Pro
				</ButtonV2>
			</div>
			<div className="info">
				<p className="description xs">
					For households who want a white glove home expense management. An
					Ownwell expert researches all your expenses and creates a personalized
					plan to reduce them.
				</p>
			</div>
			<ConciergeProFeatures />
		</div>
	);
};

export const ConciergeProFeatures = () => {
	return (
		<div className="features">
			<p className="feature-heading">What's Included?</p>
			<div className="feature-list">
				<p className="pre-requisite">Everything in Concierge Plan +</p>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Auto-pilot cost cutting at every chance we can!</span>
					</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Personalized maintenance recommendations</span>
					</p>
				</div>
				<div className="list-item">
					<p className="list-item-label">
						<span className="icon">
							<CircleCheck />
						</span>
						<span>Dedicated home manager for your property</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default ConciergeProOptionCard;
