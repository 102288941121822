import React, { ReactElement, FC, SVGProps, useMemo } from "react";

import Link from "next/link";

import { Label } from "elements/Input";
import { Button, QuickLink } from "components";
import {
	PropertyDetails,
	AppealStatus,
	NeedsAttentionReasons,
	UserData,
	PropertyDetailsAppeal,
	AppealTrackingStatus,
	InsuranceStatus,
	ExemptionStatus,
} from "utils/types";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import moment from "moment";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import constants from "utils/constants";

import ProcessingIcon from "public/account/status/processing.svg";
import EvaluatingIcon from "public/account/status/evaluating.svg";
import NeedsAttnIcon from "public/account/status/needs-attention.svg";
import MonitoringIcon from "public/account/status/monitoring.svg";
import FilingIcon from "public/account/status/filing.svg";
import HearingScheduledIcon from "public/account/status/hearing-scheduled.svg";
import AwaitingResultsIcon from "public/account/status/awaiting-results.svg";
import EvaluationCompleteIcon from "public/account/status/evaluation-complete.svg";
import AppealSuccessfulIcon from "public/account/status/appeal-successful.svg";
import VerifyingOwnershipIcon from "public/account/status/verifying-ownership.svg";
import AppealCanceledIcon from "public/account/status/appeal-canceled.svg";
import MembershipIcon from "public/account/ribbon.svg";
import PremiumDiamond from "public/premium/premium-diamond.svg";
import ReferralsActiveIcon from "public/account/referrals-active.svg";

import UserIcon from "public/account/user.svg";
import ReferIcon from "public/account/refer.svg";
import AddPropertyIcon from "public/account/add-property.svg";
import BillingIcon from "public/account/billing.svg";
import DocumentIcon from "public/account/document.svg";
import AllPropertiesIcon from "public/account/all-properties.svg";
import AddAPropertyIcon from "public/account/add-a-property.svg";

import AllPropertiesActiveIcon from "public/account/all-properties-active.svg";

import UserActiveIcon from "public/account/user-active.svg";

import DocumentActiveIcon from "public/account/document-active.svg";
import HelpIcon from "public/account/help.svg";
import HelpActiveIcon from "public/account/help-active.svg";
import OwnwellIcon from "public/nav-bar-ownwell-logo.svg";
import LogoutIcon from "public/account/logout.svg";
import LogoutActiveIocn from "public/account/logout-active.svg";
import InvoiceIcon from "public/account/invoice.svg";
import InvoiceActiveIcon from "public/account/invoice-active.svg";
import InvoiceDueIcon from "public/account/invoice-due.svg";
import SettingsIcon from "public/account/settings.svg";
import SettingsActiveIcon from "public/account/settings-active.svg";
import {
	states_with_viable_exemptions,
	states_with_viable_exemptions_savings,
} from "components/pages/Account/Property/Exemptions/questionFlowMachines/research.machine";
// import AddPropertyIcon from "public/plus-circle-outline.svg";

export const displayInterestRate = (interestRate: string | number | null) => {
	if (interestRate === null) return "-";
	if (typeof interestRate === "string") {
		interestRate = parseFloat(interestRate);
	}

	interestRate =
		interestRate % 1 === 0 ? interestRate.toFixed(2) : interestRate;
	return interestRate.toString() + "%";
};

export const displayMortgageTermInYearsOrMonths = (value: number) =>
	value % 12 === 0 ? `${value / 12} year` : `${value} months`;

export const displayNullableField = (data: any, fallback?: any) => {
	if (!fallback) fallback = "-";
	if (!data) return fallback;
	return data;
};

export const isPostitive = (value?: string | number | null) => {
	if (typeof value === "string") {
		value = parseFloat(value);
	}
	if (value === null || value === undefined || isNaN(value)) return false;
	return value > 0;
};

export const displayNullableDollarField = (
	value?: string | number | null,
	opts?: {
		includePostiveSign?: boolean;
		includeZero?: boolean;
	}
) => {
	if (opts?.includeZero && (value === "0" || value === 0)) {
		return "$0";
	}

	const result = displayNullableField(
		value ? formatDollarStr(value) : undefined
	);
	if (opts?.includePostiveSign && isPostitive(value)) {
		return "+" + result;
	}
	return result;
};

const getEntityType = (
	property: Partial<
		Pick<PropertyDetails, "legal_owner" | "auth_company" | "auth_company_type">
	>
) => {
	const { legal_owner, auth_company, auth_company_type } = property;

	const ownerNameCombined =
		legal_owner + " " + auth_company + " " + auth_company_type;

	const isTrust = ownerNameCombined.toUpperCase().split(" ").includes("TRUST");
	const isEstate = ownerNameCombined
		.toUpperCase()
		.split(" ")
		.includes("ESTATE");

	return isTrust ? "Trust" : isEstate ? "Estate" : "Entity";
};

export const shouldSeeProfile = (
	details: PropertyDetails,
	profileDone?: boolean
): boolean => {
	if (profileDone) return false;

	// NOTE: DO NOT add a default case to this switch.
	// Builds SHOULD break when AppealStatus is
	// updated and not explicitly handled here.

	switch (details.status) {
		case "Processing":
		case "Verifying Ownership":
		case "Evaluating":
		case "Filing":
		case "Filed":
		case "Escalation Recommended":
		case "Litigation Recommended":
		case "Escalated":
		case "Hearing Scheduled":
		case "Monitoring":
			return true;
		case "Awaiting Results":
		case "Processing Escalation":
		case "Processing Litigation":
		case "Evaluation Complete":
		case "Appeal Complete":
		case "Appeal Successful":
		case "Canceled":
			return false;
	}
};

export const statusToIcon = (
	status: AppealStatus,
	{ needsAttention }: { needsAttention: boolean }
): FC<SVGProps<SVGElement>> => {
	if (needsAttention) return NeedsAttnIcon;

	switch (status) {
		case "Monitoring":
			return MonitoringIcon;
		case "Processing":
			return ProcessingIcon;
		case "Verifying Ownership":
			return VerifyingOwnershipIcon;
		case "Evaluating":
			return EvaluatingIcon;
		case "Filing":
			return FilingIcon;
		case "Filed":
			return FilingIcon;
		case "Hearing Scheduled":
			return HearingScheduledIcon;
		case "Awaiting Results":
			return AwaitingResultsIcon;
		case "Escalation Recommended":
		case "Litigation Recommended":
			return NeedsAttnIcon;
		case "Processing Escalation":
		case "Processing Litigation":
			return ProcessingIcon;
		case "Escalated":
			return AwaitingResultsIcon;
		case "Evaluation Complete":
		case "Appeal Complete":
			return EvaluationCompleteIcon;
		case "Appeal Successful":
			return AppealSuccessfulIcon;
		case "Canceled":
			return AppealCanceledIcon;
	}
};

export const appealStateToArticles: ({
	appeal,
	property,
}: {
	appeal?: PropertyDetailsAppeal;
	property?: PropertyDetails;
}) => Array<{ question: string; href: string }> = ({ appeal, property }) => {
	if (!appeal || !property) return [];
	switch (appeal.status) {
		case "Monitoring":
			return [
				{
					question: "How do I refer someone to Ownwell?",
					href: "/help/article/12164052333851-how-do-i-refer-someone-to-ownwell",
				},
				{
					question: "How does the county assess my property?",
					href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
				},
				{
					question: `Can I still file ${prefixIndefiniteArticle(
						stateSpecificAppealWord(property.state)
					)} if it's past the deadline?`,
					href: "/help/article/11811767427099-can-i-still-file-an-appeal-if-its-past-the-deadline-",
				},
			];
		case "Processing":
			return [
				{
					question: "How do I refer someone to Ownwell?",
					href: "/help/article/12164052333851-how-do-i-refer-someone-to-ownwell",
				},
				{
					question: "How does the county assess my property?",
					href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
				},
				{
					question: `How quickly will my ${stateSpecificAppealWord(
						property.state
					)} be filed?`,
					href: "/help/article/8304890329499-how-quickly-will-my-appeal-be-filed",
				},
				{
					question: "How do I complete the annual property survey?",
					href: "/help/article/12161185723419-how-do-i-complete-the-annual-property-survey",
				},
			];
		case "Verifying Ownership":
			return [
				{
					question:
						"My account says information required or needs attention. What does that mean?",
					href: "/help/article/8326530411803-my-account-says-information-required-or-needs-attention-what-does-that-mean",
				},
				{
					question: "How do I upload documents?",
					href: "/help/article/12162722594075-how-do-i-upload-documents",
				},
			];
		case "Evaluating":
			return [
				{
					question: `How quickly will my ${stateSpecificAppealWord(
						property.state
					)} be filed?`,
					href: "/help/article/8304890329499-how-quickly-will-my-appeal-be-filed",
				},
				{
					question: "How does the county assess my property?",
					href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
				},
				{
					question: "How do I edit or change details about my property?",
					href: "/help/article/12160883088027-how-do-i-edit-or-change-details-about-my-property",
				},
				{
					question: "How do I complete the annual property survey?",
					href: "/help/article/12161185723419-how-do-i-complete-the-annual-property-survey",
				},
			];
		case "Filing":
			return [
				{
					question: `How quickly will my ${stateSpecificAppealWord(
						property.state
					)} be filed?`,
					href: "/help/article/8304890329499-how-quickly-will-my-appeal-be-filed",
				},
				{
					question: "Is my Property Tax Consultant local to my area?",
					href: "/help/article/11784472252315-is-my-property-tax-consultant-local-to-my-area-",
				},
				{
					question: "How do I edit or change details about my property?",
					href: "/help/article/12160883088027-how-do-i-edit-or-change-details-about-my-property",
				},
			];
		case "Filed":
			return [
				{
					question: "How long does the whole process take?",
					href: "/help/article/8304861905819-how-long-does-the-whole-process-take",
				},
				{
					question: "Do I need to attend my hearing?",
					href: "/help/article/11763980030747-do-i-need-to-attend-my-hearing-",
				},
				{
					question: "How do I edit or change details about my property?",
					href: "/help/article/12160883088027-how-do-i-edit-or-change-details-about-my-property",
				},
			];
		case "Hearing Scheduled":
			return [
				{
					question: "Do I need to attend my hearing?",
					href: "/help/article/11763980030747-do-i-need-to-attend-my-hearing-",
				},
				{
					question: `If my ${stateSpecificAppealWord(
						property.state
					)} isn't finished, will I still need to pay my tax bill?`,
					href: "/help/article/13769343512475-if-my-appeal-isnt-finished-will-i-still-need-to-pay-my-tax-bill-",
				},
			];
		case "Escalation Recommended":
			return [];
		case "Awaiting Results":
			return [
				{
					question: `If my ${stateSpecificAppealWord(
						property.state
					)} isn't finished, will I still need to pay my tax bill?`,
					href: "/help/article/13769343512475-if-my-appeal-isnt-finished-will-i-still-need-to-pay-my-tax-bill-",
				},
				{
					question: "How are tax exemptions taken into account?",
					href: "/help/article/8807275849755-how-are-tax-exemptions-taken-into-account",
				},
			];
		case "Processing Escalation":
			return [];
		case "Escalated":
			return [];
		case "Evaluation Complete":
			if (appeal.reject_reason?.includes("Authorization Expired")) {
				return [
					{
						question: `Can I still file ${prefixIndefiniteArticle(
							stateSpecificAppealWord(property.state)
						)} if it's past the deadline?`,
						href: "/help/article/11811767427099-can-i-still-file-an-appeal-if-its-past-the-deadline-",
					},
					{
						question: "How does the county assess my property?",
						href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
					},
					{
						question: `What is Auto ${stateSpecificAppealWord(property.state, {
							capitalize: true,
						})}?`,
						href: "/help/article/8304804514459-what-is-auto-appeal",
					},
				];
			}

			if (appeal.reject_reason?.includes("Past Filing Deadline")) {
				return [
					{
						question: `Can I still file ${prefixIndefiniteArticle(
							stateSpecificAppealWord(property.state)
						)} if it's past the deadline?`,
						href: "/help/article/11811767427099-can-i-still-file-an-appeal-if-its-past-the-deadline-",
					},
					{
						question: "How does the county assess my property?",
						href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
					},
					{
						question: `What is Auto ${stateSpecificAppealWord(property.state, {
							capitalize: true,
						})}?`,
						href: "/help/article/8304804514459-what-is-auto-appeal",
					},
				];
			}

			if (appeal.reject_reason?.includes("Takeover")) {
				return [
					{
						question: "How does the county assess my property?",
						href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
					},
					{
						question: `What is Auto ${stateSpecificAppealWord(property.state, {
							capitalize: true,
						})}?`,
						href: "/help/article/8304804514459-what-is-auto-appeal",
					},
				];
			}

			return [
				{
					question: "How does the county assess my property?",
					href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
				},
				{
					question: `What is Auto ${stateSpecificAppealWord(property.state, {
						capitalize: true,
					})}?`,
					href: "/help/article/8304804514459-what-is-auto-appeal",
				},
				{
					question: "Why did my protest get rejected?",
					href: "/help/article/11810655415195-why-did-my-appeal-get-rejected",
				},
			];
		case "Appeal Complete":
			return [
				{
					question: "How does the county assess my property?",
					href: "/help/article/8490403203227-how-does-the-county-assess-my-property",
				},
				{
					question: `What is Auto ${stateSpecificAppealWord(property.state, {
						capitalize: true,
					})}?`,
					href: "/help/article/8304804514459-what-is-auto-appeal",
				},
				{
					question: `Why did my ${stateSpecificAppealWord(
						property.state
					)} get rejected?`,
					href: "/help/article/11810655415195-why-did-my-appeal-get-rejected",
				},
			];
		case "Appeal Successful":
			if (
				parseInt(appeal.final_market_value?.replace(/[^0-9\.]/g, "") ?? "0") >=
				parseInt(appeal.market_value_to_beat ?? appeal.original_market_value!)
			) {
				return [
					{
						question: "How are tax exemptions taken into account?",
						href: "/help/article/8807275849755-how-are-tax-exemptions-taken-into-account",
					},
					{
						question: "How are property taxes calculated?",
						href: "/help/article/8725590230939-how-are-property-taxes-calculated",
					},
					{
						question: `What is Auto ${stateSpecificAppealWord(property.state, {
							capitalize: true,
						})}?`,
						href: "/help/article/8304804514459-what-is-auto-appeal",
					},
				];
			} else if (appeal?.invoice_ref === "low_savings") {
				return [
					{
						question: "How are tax exemptions taken into account?",
						href: "/help/article/8807275849755-how-are-tax-exemptions-taken-into-account",
					},
					{
						question: "How are property taxes calculated?",
						href: "/help/article/8725590230939-how-are-property-taxes-calculated",
					},
					{
						question: "What are your fees? How does pricing work?",
						href: "/help/article/8304832647451-what-are-your-fees-how-does-pricing-work",
					},
				];
			} else {
				return [
					{
						question: "How do I pay my Ownwell invoice?",
						href: "/help/article/14355533992347-how-do-i-pay-my-ownwell-invoice",
					},
					{
						question: "How are tax exemptions taken into account?",
						href: "/help/article/8807275849755-how-are-tax-exemptions-taken-into-account",
					},
					{
						question:
							"What if my tax rate is different than the one on my invoice from Ownwell?",
						href: "/help/article/11781483223067-what-if-my-tax-rate-is-different-than-the-one-on-my-invoice-from-ownwell-",
					},
				];
			}
		case "Canceled":
			return [];
		default:
			return [];
	}
};

// Function for separating front end copy from internal statuses
export const appealStateToTitle = ({
	status,
	state,
}: {
	status: AppealStatus | AppealTrackingStatus;
	state?: string;
}) => {
	if (status === "Hearing Scheduled") return "Awaiting Hearing";

	if (status === "Appeal Successful")
		return `${stateSpecificAppealWord(state, { capitalize: true })} Successful`;
	if (status === "Appeal Complete")
		return `${stateSpecificAppealWord(state, { capitalize: true })} Complete`;
	if (status === "Signed Up to Appeal")
		return `Signed Up to ${stateSpecificAppealWord(state, {
			verbal_phrase: true,
			capitalize: true,
		})}`;
	if (status === "Appeal Created")
		return `${stateSpecificAppealWord(state, { capitalize: true })} Created`;

	if (status === "Canceled")
		return `${stateSpecificAppealWord(state, { capitalize: true })} Canceled`;

	return status;
};

export const stateSpecificAppealWord = (
	state?: string,
	opts?: {
		capitalize?: boolean;
		verbal_phrase?: boolean;
		gerund_phrase?: boolean;
		plural?: boolean;
	}
) => {
	if (state === "TX") {
		let result = opts?.capitalize ? "Protest" : "protest";
		if (opts?.gerund_phrase) result += "ing";
		if (opts?.plural) result += "s";
		return result;
	}

	if (state === "NY") {
		if (opts?.gerund_phrase) {
			return opts?.capitalize ? "Filing a Grievance" : "filing a grievance";
		}

		if (opts?.verbal_phrase) {
			return opts?.capitalize ? "File a Grievance" : "file a grievance";
		}

		let result = opts?.capitalize ? "Grievance" : "grievance";
		if (opts?.plural) result += "s";
		return result;
	}

	if (state === "FL") {
		let result = opts?.capitalize ? "Petition" : "petition";
		if (opts?.gerund_phrase) {
			result += "ing";
		}
		if (opts?.plural) {
			result += "s";
		}
		return result;
	}

	let result = opts?.capitalize ? "Appeal" : "appeal";
	if (opts?.gerund_phrase) result += "ing";
	if (opts?.plural) result += "s";
	return result;
};

export const stateSpecificExemptionWord = (
	state?: string,
	opts?: {
		plural?: boolean;
		capitalize?: boolean;
	}
) => {
	let result: string;
	if (state === "NY") {
		result = opts?.capitalize ? "STAR Credit" : "STAR credit";
		if (opts?.plural) result += "s";
		return result;
	}

	result = opts?.capitalize ? "Exemption" : "exemption";
	if (opts?.plural) result += "s";
	return result;
};

export const prefixIndefiniteArticle = (
	str: string,
	opts?: { capitalize?: boolean }
) => {
	const a = opts?.capitalize ? "A" : "a";
	return /^(a|e|i|o|u)/i.test(str) ? `${a}n ${str}` : `${a} ${str}`;
};

export const currentAppealStatusToBody = (
	property: PropertyDetails
): ReturnType<typeof appealStateToBody> => {
	const currentAppeal = getMostRecentAppeal(property);

	if (!currentAppeal) return { preview: null, expanded: null };

	return appealStateToBody({
		property,
		appeal: currentAppeal,
		status: currentAppeal.status,
	});
};

export const appealStateToBody = ({
	appeal,
	status,
	property,
}: {
	property: PropertyDetails;
	status: AppealStatus | AppealTrackingStatus;
	appeal: Pick<
		PropertyDetailsAppeal,
		| "invoice_ref"
		| "hearing_status"
		| "case_reason"
		| "tax_year"
		| "needs_attention"
		| "needs_attention_reason"
		| "reject_reason"
		| "final_market_value"
		| "market_value_to_beat"
		| "original_market_value"
		| "archive_reason"
	>;
}): {
	preview: JSX.Element | null;
	expanded: JSX.Element | null;
} => {
	let preview: JSX.Element | null = null;
	let expanded: JSX.Element | null = null;

	const isArbitrationNar =
		appeal.needs_attention_reason.length === 1 &&
		appeal.needs_attention_reason[0].name === "Arbitration";

	if (appeal.needs_attention && !isArbitrationNar) {
		if (appeal?.needs_attention_reason.length > 1) {
			preview = (
				<p className="sm">
					In order for us to move forward with your{" "}
					{stateSpecificAppealWord(property.state)}, there is some information
					about your property that needs to be corrected or updated.
				</p>
			);

			return { preview, expanded };
		} else if (
			appeal.needs_attention_reason.length === 1 &&
			SHOULD_SEE_NEEDS_ATTN_MODAL.includes(
				appeal.needs_attention_reason[0].name
			)
		) {
			preview = (
				<p className="sm">
					{getNeedsAttentionBody(appeal.needs_attention_reason[0], property)}
				</p>
			);
			return { preview, expanded };
		}

		preview = (
			<p className="sm">
				We need more information to continue your{" "}
				{stateSpecificAppealWord(property.state)}. Please contact support to get
				your {stateSpecificAppealWord(property.state)} back on track.
			</p>
		);
		return { preview, expanded };
	}

	if (
		status === "Monitoring" &&
		appeal.reject_reason === "APPEAL_COUNTY_OVERSUBSCRIBED"
	) {
		preview = (
			<p className="sm">
				We're monitoring your tax assessment for opportunities to save for next
				year and beyond. Our team of experienced tax experts will be standing by
				to review and evaluate your property for a reduction as soon as the
				local tax assessor publishes the {appeal.tax_year + 1} assessment for
				your property.
			</p>
		);
		expanded = (
			<>
				<p className="sm">
					Rest assured that our team works tirelessly year-round to stay
					up-to-date on the latest real estate market trends in your area. Our
					expertise and relationship with your local tax assessor enable us to
					achieve better outcomes for you.
				</p>
				<p className="sm">
					In the meantime, please keep us informed of any changes or updates to
					the condition of your property that could help us strengthen your case
					by completing your property survey. At Ownwell, we are committed to
					providing you with the best possible experience and tax reduction
				</p>
			</>
		);
		return { preview, expanded };
	}

	switch (status) {
		case "Monitoring":
			preview = (
				<p className="sm">
					We’re currently waiting for the local tax assessor to publish the{" "}
					{appeal.tax_year} assessment for your property, and our team of
					experienced tax experts is standing by to review and evaluate your
					case. Please refrain from filing your own{" "}
					{stateSpecificAppealWord(property.state)} or accepting any offers from
					the county as it can prevent us from achieving the best possible tax
					reduction for you.
				</p>
			);
			expanded = (
				<>
					<p className="sm">
						Rest assured that our team works tirelessly year-round to stay
						up-to-date on the latest real estate market trends in your area. Our
						expertise and relationship with your local tax assessor enable us to
						achieve better outcomes for you.
					</p>
					<p className="sm">
						In the meantime, please keep us informed of any changes or updates
						to the condition of your property that could help us strengthen your
						case by completing your property survey. At Ownwell, we are
						committed to providing you with the best possible experience and tax
						reduction.
					</p>
				</>
			);
			return { preview, expanded };
		case "Verifying Ownership":
			preview = (
				<p className="sm">
					We are currently verifying the information you provided matches the
					county’s ownership records to ensure we can accurately file and handle
					your {stateSpecificAppealWord(property.state)}. To help speed things
					up, we recommend uploading a copy of your notice of value or closing
					disclosure.
				</p>
			);
			expanded = (
				<p className="sm">
					Our team of experts is working diligently to complete this step as
					soon as possible so we can get your{" "}
					{stateSpecificAppealWord(property.state)} underway. We’ll be in touch
					if we need any further information.
				</p>
			);
			return { preview, expanded };
		case "Verified as Legal Owner":
			return { preview, expanded };
		case "Processing":
			preview = (
				<p className="sm">
					We’ve received your {stateSpecificAppealWord(property.state)}{" "}
					submission and are beginning to review the information you’ve
					provided. Make sure we have the most updated information and evidence
					regarding the condition of your property by completing your property
					survey.
				</p>
			);
			expanded = (
				<p className="sm">
					Every property is unique, and our approach is tailored to each
					property’s specific circumstances. The more we know about your
					property, the more ways we can pursue savings on your behalf. This can
					include any damage to the structure, such as cracks in the foundation,
					incidents of flooding or fire, or recent appraisals and inspections of
					the property. A local tax professional who is intimately familiar with
					your county’s tax {stateSpecificAppealWord(property.state)} process
					will evaluate your property to put together the most compelling case.
				</p>
			);
			return { preview, expanded };
		case "Assessment Details Received":
			preview = (
				<p className="sm">
					New assessment and property data has been matched with your account.
				</p>
			);
			return { preview, expanded };
		case "Evaluating":
			preview = (
				<p className="sm">
					We are analyzing your property to determine the potential for tax
					reduction. Anything that would impact the market value of your
					property would be helpful for us to know to get you the best possible
					outcome.
				</p>
			);
			expanded = (
				<>
					{/*TODO: add this back in when we have the data to support it*/}
					{/* <p className="sm">
						(in area w/ possible increase from appeal) Since in your county,
						it’s possible for an assessment to increase after filing, we take
						extra care to only {stateSpecificAppealWord(property.state)}
						the cases we believe have a strong chance of winning and almost no
						chance of resulting in an increase. (/in area w/ possible increase
						from appeal)
					</p> */}
					<p className="sm">
						At Ownwell, we uniquely combine human expertise and cutting-edge
						technology. Our property tax consultants combine data such as local
						comps and market trends in addition to any evidence you provide to
						determine the fair assessment of your property. Our Savings-or-Free
						Guarantee means that if you don’t save, our services are entirely
						free.
					</p>
				</>
			);
			return { preview, expanded };
		case "Filing":
			preview = (
				<p className="sm">
					We have completed our review of your property tax{" "}
					{stateSpecificAppealWord(property.state)} and believe we have a case
					for a reduction. We are preparing to file the necessary paperwork with
					your tax assessor before the {/*county specific deadline*/}deadline.
				</p>
			);
			expanded = (
				<p className="sm">
					Every property is unique, and our approach is tailored to each
					property’s specific circumstances. Our property tax consultants build
					your case by compiling the evidence you provide with local market data
					and other homes similar in size, age, location, and condition to
					determine your property’s fair market value.
				</p>
			);
			return { preview, expanded };
		case "Filed":
			preview = (
				<p className="sm">
					We've filed the necessary documents with the{" "}
					<span className="no-translate">{property.county}</span> County tax
					assessor to start the {stateSpecificAppealWord(property.state)}{" "}
					process.
					{/* It typically takes {{average time to complete appeal in region}} to reach a final verdict on your tax assessment.  */}{" "}
					During this time, your local Ownwell property tax consultant will
					handle all aspects of your case.
				</p>
			);
			expanded = (
				<>
					<p className="sm">
						Next, we’ll continue to build the case for a reduction to your
						market value and schedule a hearing with the tax assessor to fight
						for your reduction. In a hearing, your local property tax consultant
						will present the evidence they have compiled to prove your market
						value reduction.
					</p>
					<p className="sm">
						In some cases, we are able to get a more favorable reduction by
						negotiating with the county early in the process before a formal
						hearing. You can rest assured we will take whichever path gives you
						the best chance to save on your property tax bill.
					</p>
				</>
			);
			return { preview, expanded };
		case "Hearing Scheduled":
			preview = (
				<p className="sm">
					The tax assessor's office has started organizing hearings to review
					the information we've prepared for your property tax appeal case. Your
					Ownwell tax expert will attend the hearing on your behalf to make the
					case for a reduction in your property taxes. You do not need to attend
					the hearing.
				</p>
			);
			expanded = (
				<>
					<p className="sm">
						In the hearing, we will represent you and make a case for why your
						property's assessed value should be reduced. We'll present evidence
						to support our case, such as any changes to your property that may
						impact its value along with local comps, market trends, and
						equity-based arguments. Our experienced property tax consultants
						have the knowledge and expertise to handle any questions or concerns
						from the tax assessor's office.
					</p>
					<p className="sm">
						In some cases, we are able to get a more favorable reduction by
						negotiating with the county early in the process before a formal
						hearing. You can rest assured we will take whichever path gives you
						the best chance to save on your property tax bill.{" "}
					</p>
				</>
			);
			return { preview, expanded };
		case "Awaiting Results":
			preview = (
				<p className="sm">
					Your local Ownwell tax expert has presented your appeal to the county
					tax assessor and review board, and we're now waiting for their
					decision. We'll update you as soon as the results become available.
				</p>
			);
			expanded = (
				<p className="sm">
					To ensure that you'll be accurately billed, we’ll confirm that any
					reduction in your assessed value is reflected in the county records
					before sharing them with you. Remember, we only charge a fee if we're
					successful in getting a reduction in your property tax bill.
				</p>
			);
			return { preview, expanded };
		case "Escalation Recommended":
			preview = (
				<p className="sm">
					We recommend filing arbitration to get your assessment further
					reduced.
				</p>
			);
			return { preview, expanded };
		case "Litigation Recommended":
			preview = (
				<p className="sm">
					After reviewing your case for your{" "}
					{stateSpecificAppealWord(property.state)}, we beleive the best course
					of action to maximize results is to pursue litigation.
				</p>
			);
			return { preview, expanded };
		case "Processing Escalation":
			preview = (
				<p className="sm">
					We are currently preparing the appropriate documents to escalate your
					appeal with <span className="no-translate">{property.county}</span>{" "}
					County. We will reach out if any further information is needed.
				</p>
			);
			return { preview, expanded };
		case "Processing Litigation":
			preview = (
				<p className="sm">
					We partnered with a local law firm to file the lawsuit to district
					court. We will then begin negotiations with the county appraisal
					district, exchanging evidence and settlement offers. We will notify
					you when we have results.
				</p>
			);
			return { preview, expanded };
		case "Escalated":
			if (appeal.hearing_status?.includes("Correction")) {
				preview = (
					<p className="sm">
						This appeal has been escalated with{" "}
						<span className="no-translate">{property.county}</span> County to
						pursue an assessment reduction. The escalation process can often
						take many months, depending on the responsiveness of{" "}
						<span className="no-translate">{property.county}</span> County
						staff. We will reach out if any further information is needed.
					</p>
				);
			} else {
				preview = (
					<p className="sm">
						The county-level {stateSpecificAppealWord(property.state)} has been
						completed. We're now taking this{" "}
						{stateSpecificAppealWord(property.state)} to arbitration to pursue a
						larger reduction.
					</p>
				);
			}
			return { preview, expanded };
		case "Evaluation Complete":
			if (appeal.reject_reason?.includes("Authorization Expired")) {
				preview = (
					<p className="sm">
						Despite multiple attempts to contact you, we couldn't obtain the
						updated authorization needed to represent{" "}
						<span className="no-translate">{property?.address}</span> this year.
						Periodic signature renewal is necessary in certain jurisdictions,
						including when the county updates records, previous signatures are
						illegible, or other companies challenge our authorization. Since we
						didn't receive authorization before{" "}
						<span className="no-translate">{property?.county}</span> County's
						deadline, we couldn't proceed with your appeal.
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("Past Filing Deadline")) {
				preview = (
					<p className="sm">
						Unfortunately the{" "}
						<span className="no-translate">{property?.county}</span> County
						deadline has passed and they will not accept your appeal for{" "}
						{appeal.tax_year}. Don’t worry — now that you've signed up, we've
						got you covered for next year!
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("Business Personal Property")) {
				preview = (
					<p className="sm">
						Our team has indicated that this signup was for personal property.
						Currently, Ownwell can only service real property. Real property
						refers to land and permanent structures attached to that land, while
						personal property refers to movable possessions. The tax assessment
						and appeal processes are different for each property type. We will
						reach out as soon as we can service your personal property.
					</p>
				);
				return { preview, expanded };
			}
			if (appeal.reject_reason?.includes("Inactive geography")) {
				preview = (
					<p className="sm">
						Unfortunately, we no longer service properties in{" "}
						<span className="no-translate">{property?.county}</span> County. We
						will of course keep in touch with you if the situation changes in
						the future. In the meantime, we'll continue to look for
						opportunities to save you money within our active service areas.
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("Cannot Verify")) {
				preview = (
					<p className="sm">
						The information you provided during signup doesn't match the
						ownership records on file with{" "}
						<span className="no-translate">{property?.county}</span> County.
						Since the ownership mismatch was not resolved before the county’s
						filing deadline, we can't proceed with a property tax appeal for{" "}
						<span className="no-translate">{property?.address}</span> this year.
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("APPEAL_COUNTY_OVERSUBSCRIBED")) {
				preview = (
					<p className="sm">
						Unfortunately we're no longer accepting new submissions for property
						tax {stateSpecificAppealWord(property?.state)} in{" "}
						<span className="no-translate">{property?.county}</span> County this
						year. Don’t worry — now that you've signed up, we've got you covered
						for next year!
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("Takeover")) {
				preview = (
					<p className="sm">
						We are unable to manage your appeal this year since you or another
						company has already filed an appeal. We'll continue to monitor and
						evaluate your new tax assessments for next year and beyond.
					</p>
				);
				return { preview, expanded };
			}

			if (appeal.reject_reason?.includes("Assessment Frozen")) {
				preview = (
					<p className="sm">
						It appears your property's assessment value is frozen under GA law
						299(c). This is beneficial for your property tax obligation, since
						it prevents your assessment from increasing. If you file an appeal
						while on this freeze, the county has the option of{" "}
						<span className="bold">increasing</span> your value, which would be
						counterproductive to our goal of saving you money. To avoid any
						potential increases, we do not recommend filing an appeal this year.
					</p>
				);
				return { preview, expanded };
			}

			preview = (
				<p className="sm">
					Our tax specialists weren’t able to find enough evidence to support a
					reduction to the taxable value of this property at this time. We'll
					continue to monitor your assessment and provide updates whenever we
					find a new opportunity for you to save.
				</p>
			);
			return { preview, expanded };
		case "Appeal Complete":
			preview = (
				<p className="sm">
					Unfortunately, the assessor and review board have determined that this
					property is already below its estimated market value and as a result,
					there will be no change to your tax bill this year. We’ll continue to
					monitor your assessment and look forward to helping you save next time
					around.
				</p>
			);

			const appealCompleteReasons = [
				/* SUBJECT_SALE */
				appeal?.case_reason
					?.split(",")
					?.some(reason => reason === "Subject Sale") && (
					<>
						<strong>Recent Property Purchase Price:</strong> The property's most
						recent purchase was higher than its current assessed value. In cases
						like these, where the Appraisal District or Board have received the
						sale price from either the buyer, seller, or a verified listing
						service (like the MLS), they’ll often uphold the Appraisal
						District’s initial value assessment.
					</>
				),
				/* SUBJECT_SALE_ADJUSTED */
				appeal?.case_reason
					?.split(",")
					?.some(reason =>
						["Subject Sale Lower", "Subject Sale Higher"].includes(reason)
					) && (
					<>
						<strong>Adjusted Purchase Price:</strong> The property’s most recent
						purchase price, after adjusting for market appreciation through
						January 1st of this year, was higher than its current assessed
						value. In cases like these, where neighborhood values have
						appreciated rapidly, and the Appraisal District or Board have
						received the sale price from either the buyer, seller, or a verified
						listing service (like the MLS), they’ll often uphold the Appraisal
						District’s initial value assessment.
					</>
				),
				/* ASSESSMENT_NOT_INCREASED */
				appeal?.case_reason
					?.split(",")
					?.some(reason => reason === "No Increase") && (
					<>
						<strong>No Assessment Increase:</strong> The property’s assessment
						did not increase this year. The Appraisal Review Board will not
						award a reduction in value below last year's certified tax
						assessment value.
					</>
				),
				/* MARKET_SALES */
				appeal?.case_reason
					?.split(",")
					?.some(reason => ["Market Sales Lower"].includes(reason)) && (
					<>
						<strong>Sales of Comparable Properties:</strong> Sales of comparable
						properties in the neighborhood overwhelmingly indicated that the
						market value of the property as of January 1st of this year was
						equal to or greater than its assessed value.
					</>
				),
				/* MARKET_SALES_ADJUSTED */
				appeal?.case_reason
					?.split(",")
					?.some(reason => ["Market Sales Higher"].includes(reason)) && (
					<>
						<strong>Adjusted Sales of Comparable Properties:</strong> After
						adjusting for appreciation through the end of last year, sales of
						comparable properties in the neighborhood overwhelmingly indicated
						that the market value of the property as of January 1st of this year
						was equal to or greater than its assessed value.
					</>
				),
				/* EQUITY_COMPS */
				appeal?.case_reason
					?.split(",")
					?.some(reason => ["Equity Comparables Higher"].includes(reason)) && (
					<>
						<strong>Equity Comparables:</strong> After making adjustments for
						differences in age, size, construction, features, location, and
						more, this property is assessed at a lower value than other
						comparable properties in the neighborhood.
					</>
				),
				/* INCOME_CAP */
				appeal?.case_reason
					?.split(",")
					?.some(reason =>
						["Income Capitalization Higher"].includes(reason)
					) && (
					<>
						<strong>Income Capitalization:</strong> The property’s actual income
						(or income-producing potential) was higher than that of other
						similarly-assessed properties.
					</>
				),
				/* MISSING_FINANCIALS */
				appeal?.case_reason
					?.split(",")
					?.some(reason => ["Missing Financials"].includes(reason)) && (
					<>
						<strong>Income-Producing Potential:</strong> The property’s actual
						income (or income-producing potential) was higher than that of other
						similarly-assessed properties, and there was insufficient
						information or evidence available to us to suggest otherwise.
					</>
				),
				/* COST_APPROACH */
				appeal?.case_reason
					?.split(",")
					?.some(reason => ["Cost Approach"].includes(reason)) && (
					<>
						<strong>Costs to Construct:</strong> There were limited or no sales
						comparables, or similar properties nearby to base a value opinion on
						- or the property recently finished construction. Based on the
						estimated costs to construct the property today, it appears to be
						fairly assessed.
					</>
				),
				/* PROPERTY_CHARACTERISTICS */
				appeal?.case_reason
					?.split(",")
					?.some(reason =>
						["Corrected Property Characteristics"].includes(reason)
					) && (
					<>
						<strong>Property Characteristics:</strong> After adjusting for
						characteristics of the property which were previously unknown or
						unaccounted for in the assessor’s initial valuation, this property's
						assessment remains lower than other properties in the area.
					</>
				),
			].filter(reason => !!reason);

			expanded =
				appealCompleteReasons.length > 0 ? (
					<p className="sm">
						The following factors caused the Appraisal District’s assessment to
						be upheld:
						{appealCompleteReasons.map((reason, idx) => (
							<li key={idx} className="sm">
								{reason}
							</li>
						))}
					</p>
				) : null;

			return { preview, expanded };
		case "Appeal Successful":
			if (
				parseInt(appeal.final_market_value?.replace(/[^0-9\.]/g, "") ?? "0") >=
				parseInt(appeal.market_value_to_beat ?? appeal.original_market_value!)
			) {
				preview = (
					<p className="sm">
						Your {stateSpecificAppealWord(property.state)} was successful and we
						were able to reduce your assessment. However, due to your existing
						exemption(s), your taxable value is below this assessed value, which
						means your property taxes were already lower than if your home was
						being taxed at market value. As a result, you will not actually see
						a reduction in your tax bill this year.
					</p>
				);

				const appealSuccessfulExemptionReasons = [
					/* MARKET_SALES */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Market Sales Lower"].includes(reason)) && (
						<>
							<strong>Sales of Comparable Properties:</strong> While market
							sales through the end of last year supported an adjustment to the
							initial notice value, there wasn’t enough evidence to support a
							larger reduction based on sales of similar properties in the area.
						</>
					),
					/* EQUITY_CASE */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Equity Comparables Lower"].includes(reason)) && (
						<>
							<strong>Equity Comparables:</strong> The assessed values of other
							properties in the area indicated some adjustment was necessary.
							However, after adjusting for differences in age, size,
							construction, features, location, and more, there was not enough
							evidence to support a larger reduction.
						</>
					),
					/* COST_APPROACH */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Cost Approach"].includes(reason)) && (
						<>
							<strong>Costs to Construct:</strong> There were limited or no
							sales comparables, or similar properties nearby to base a value
							opinion on — or the property recently finished construction. We
							were able to earn a partial reduction based on the estimated costs
							to construct the property today, but we didn’t have sufficient
							basis to support a larger reduction.
						</>
					),
					/* PROPERTY_CHARACTERISTICS */
					appeal?.case_reason
						?.split(",")
						?.some(reason =>
							["Corrected Property Characteristics"].includes(reason)
						) && (
						<>
							<strong>Property Characteristics:</strong> After adjusting for
							characteristics of the property which were previously unknown or
							unaccounted for in the assessor’s initial valuation, we were able
							to earn a partial assessment reduction. Despite these adjustments,
							we didn’t have evidence supporting a larger reduction.
						</>
					),
					/* PROPERTY_DEFECT */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Property Defect"].includes(reason)) && (
						<>
							<strong>Known Issues and Deferred Maintenance:</strong> We used
							evidence of known issues or deferred maintenance to earn a partial
							change to the assessment, but didn’t have evidence to support a
							larger reduction.
						</>
					),
				].filter(reason => !!reason);

				expanded = (
					<>
						<p className="sm">
							With the dramatic increase in property prices in the last few
							years, the assessed market value of your home has exceeded the
							increase that your exemption(s) allow in your taxable value each
							year. As a result, this elevated market value makes it challenging
							to reduce your assessment by enough to get it below your “capped”
							taxable value.
						</p>
						<p className="sm">
							However, this reduction will help minimize future increases to
							your tax bill because it lowers the basis that the county uses to
							determine assessment increases. We’ll continue to monitor your
							assessment and look forward to helping you save next time around.
						</p>
						{appealSuccessfulExemptionReasons.length > 0 ? (
							<p className="sm">
								The following factors affected our ability to earn a larger
								reduction:
								<ul>
									{appealSuccessfulExemptionReasons.map((reason, idx) => (
										<li key={idx} className="sm">
											{reason}
										</li>
									))}
								</ul>
							</p>
						) : null}
					</>
				);

				return { preview, expanded };
			} else if (appeal?.invoice_ref === "low_savings") {
				preview = (
					<p className="sm">
						This property tax {stateSpecificAppealWord(property.state)} was
						successful, and your tax assessment has been reduced. However, due
						to the small decrease in your tax bill, we’ve waived our usual{" "}
						{/*TODO: add dynamic contingency %*/}contingency fee for you this
						year.
					</p>
				);

				const appealSuccessfulLowSavingsReasons = [
					/* MARKET_SALES */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Market Sales Lower"].includes(reason)) && (
						<>
							<strong>Sales of Comparable Properties:</strong> While market
							sales through the end of last year supported an adjustment to the
							initial notice value, there wasn’t enough evidence to support a
							larger reduction based on sales of similar properties in the area.
						</>
					),
					/* EQUITY_CASE */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Equity Comparables Lower"].includes(reason)) && (
						<>
							<strong>Equity Comparables:</strong> The assessed values of other
							properties in the area indicated some adjustment was necessary.
							However, after adjusting for differences in age, size,
							construction, features, location, and more, there was not enough
							evidence to support a larger reduction.
						</>
					),
					/* COST_APPROACH */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Cost Approach"].includes(reason)) && (
						<>
							<strong>Costs to Construct:</strong> There were limited or no
							sales comparables, or similar properties nearby to base a value on
							— or the property recently finished construction. We were able to
							earn a partial reduction based on the estimated costs to construct
							the property today, but we didn’t have sufficient basis to support
							a larger reduction.
						</>
					),
					/* PROPERTY_CHARACTERISTICS */
					appeal?.case_reason
						?.split(",")
						?.some(reason =>
							["Corrected Property Characteristics"].includes(reason)
						) && (
						<>
							<strong>Property Characteristics:</strong> After adjusting for
							characteristics of the property which were previously unknown or
							unaccounted for in the assessor’s initial valuation, we were able
							to earn a partial assessment reduction. Despite these adjustments,
							we didn’t have evidence supporting a larger reduction.
						</>
					),
					/* PROPERTY_DEFECT */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Property Defect"].includes(reason)) && (
						<>
							<strong>Known Issues and Deferred Maintenance:</strong> We used
							evidence of known issues or deferred maintenance to earn a partial
							change to the assessment, but didn’t have evidence to support a
							larger reduction.
						</>
					),
				].filter(reason => !!reason);

				expanded =
					appealSuccessfulLowSavingsReasons.length > 0 ? (
						<p className="sm">
							The following factors affected our ability to earn a larger
							reduction:
							<ul>
								{appealSuccessfulLowSavingsReasons.map((reason, idx) => (
									<li key={idx} className="sm">
										{reason}
									</li>
								))}
							</ul>
						</p>
					) : null;

				return { preview, expanded };
			} else {
				preview = (
					<p className="sm">
						Congratulations! This property tax{" "}
						{stateSpecificAppealWord(property.state)} was successful, and your
						tax assessment has been reduced.
					</p>
				);

				const appealSuccessfulReasons = [
					/* MARKET_SALES */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Market Sales Lower"].includes(reason)) && (
						<>
							<strong>Adjusted Sales of Comparable Properties:</strong> After
							adjusting for appreciation through the end of last year, sales of
							comparable properties in the area supported an assessment
							reduction.
						</>
					),
					/* EQUITY_CASE */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Equity Comparables Lower"].includes(reason)) && (
						<>
							<strong>Adjusted Equity Comparables:</strong> After adjusting for
							differences in age, size, construction, features, and location,
							the assessed values of similar properties in the area supported
							the case for an assessment reduction.
						</>
					),
					/* COST_APPROACH */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Cost Approach"].includes(reason)) && (
						<>
							<strong>Costs to Construct:</strong> There were limited or no
							sales comparables, or similar properties nearby to base a value
							opinion on — or the property recently finished construction. We
							were able to earn a reduction based on the estimated costs to
							construct the property.
						</>
					),
					/* PROPERTY_CHARACTERISTICS */
					appeal?.case_reason
						?.split(",")
						?.some(reason =>
							["Corrected Property Characteristics"].includes(reason)
						) && (
						<>
							<strong>Property Characteristics:</strong> After adjusting for
							characteristics of the property which were previously unknown or
							unaccounted for in the assessor’s initial valuation, we were able
							to earn an assessment reduction.
						</>
					),
					/* PROPERTY_DEFECT */
					appeal?.case_reason
						?.split(",")
						?.some(reason => ["Property Defect"].includes(reason)) && (
						<>
							<strong>Known Issues and Deferred Maintenance:</strong> We used
							evidence of known issues or deferred maintenance to earn an
							assessment reduction.
						</>
					),
				].filter(reason => !!reason);

				expanded =
					appealSuccessfulReasons.length > 0 ? (
						<p className="sm">
							The reduction in your assessment was based on the following
							factors:
							<ul>
								{appealSuccessfulReasons.map((reason, idx) => (
									<li key={idx} className="sm">
										{reason}
									</li>
								))}
							</ul>
						</p>
					) : null;

				return { preview, expanded };
			}
		case "Canceled":
			if (appeal.archive_reason?.includes("Cancel - Lost To Competitor")) {
				preview = (
					<p className="sm">
						Another property tax agent has already resolved your{" "}
						{stateSpecificAppealWord(property.state)}, prohibiting us from
						representing you for this year.
					</p>
				);
			} else {
				preview = (
					<p className="sm">
						You opted out of{" "}
						{stateSpecificAppealWord(property.state, { gerund_phrase: true })}{" "}
						your property taxes for{" "}
						<span className="no-translate">
							{[property.address, property.address2].join(" ")}
						</span>{" "}
						with Ownwell this year.{" "}
						{!!property.auto_appeal
							? `We’ve got you covered! We will automatically re-evaluate your case for appeal in ${
									appeal.tax_year + 1
								}.`
							: "Ensure you never overpay property taxes again by reactivating Ownwell’s Property Tax Monitoring Service"}
					</p>
				);
			}
			return { preview, expanded };
		default:
			return { preview, expanded };
	}
};

export const statusToStepCounter = (status: AppealStatus): number => {
	switch (status) {
		case "Canceled":
			return -1;
		case "Monitoring":
		case "Processing":
		case "Verifying Ownership":
			return 0;
		case "Evaluating":
			return 1;
		case "Filing":
		case "Filed":
			return 2;
		case "Hearing Scheduled":
		case "Awaiting Results":
		case "Escalation Recommended":
		case "Processing Escalation":
		case "Litigation Recommended":
		case "Processing Litigation":
		case "Escalated":
			return 3;
		case "Evaluation Complete":
		case "Appeal Complete":
		case "Appeal Successful":
			return 4;
	}
};

export interface AccountPage {
	name: string | ReactElement;
	route: string;
	icon: any;
	activeIcon: any;
	displayName: string | null;
	mobileOnly?: boolean;
}

type AllAccountPageNames =
	| "Documents"
	| "Account"
	| "Billing"
	| "Referrals"
	| "Help"
	| "Website"
	| "LogOut"
	| "Settings"
	| "Premium"
	| "AddProperty"
	| "AllProperties"
	| "Dashboard";
export const getAllAccountPages = (
	userData: UserData | undefined
): Record<AllAccountPageNames, AccountPage> => {
	return {
		Premium: {
			name:
				userData?.account_tier === "premium"
					? "Premium Membership"
					: "Membership",
			route: "",
			icon:
				userData?.account_tier === "premium" ? PremiumDiamond : MembershipIcon,
			activeIcon:
				userData?.account_tier === "premium" ? PremiumDiamond : MembershipIcon,
			displayName: null,
		},
		Documents: {
			name: "All Documents",
			route: "/account/evidence",
			icon: DocumentIcon,
			activeIcon: DocumentIcon,
			displayName: null,
		},
		Account: {
			name: "Account",
			route: "/account/settings/user",
			icon: UserIcon,
			activeIcon: UserIcon,
			displayName: null,
		},
		Billing: {
			displayName: "Billing",
			name: !!userData?.has_outstanding_invoice ? (
				<>
					<p>Billing</p>
					<div id="account-invoice-due-pill">
						<span className="body-tiny semibold rust-dark">Invoice Due</span>
					</div>
				</>
			) : (
				"Billing"
			),
			route: "/account/invoice",
			icon: BillingIcon,
			activeIcon: BillingIcon,
		},
		Referrals: {
			displayName: "Referrals",
			name: "Refer A Friend",
			route: "/account/referrals",
			icon: ReferIcon,
			activeIcon: ReferIcon,
		},
		Help: {
			name: "Help Center",
			route: "/help",
			icon: HelpIcon,
			activeIcon: HelpIcon,
			displayName: null,
			mobileOnly: true,
		},
		Website: {
			name: "Website",
			route: "/",
			icon: OwnwellIcon,
			activeIcon: OwnwellIcon,
			displayName: null,
		},
		LogOut: {
			name: "Log Out",
			route: "/account/logout",
			icon: LogoutIcon,
			activeIcon: LogoutIcon,
			displayName: null,
		},
		Settings: {
			name: "Settings",
			route: "/account/menu",
			icon: SettingsIcon,
			activeIcon: SettingsIcon,
			displayName: null,
		},
		AddProperty: {
			name: "Add Property",
			route: "/account/add-property",
			icon: AddPropertyIcon,
			activeIcon: AddPropertyIcon,
			displayName: null,
		},
		AllProperties: {
			name: "All Properties",
			route: "/account/properties",
			icon: AllPropertiesIcon,
			activeIcon: AllPropertiesIcon,
			displayName: null,
			mobileOnly: true,
		},
		Dashboard: {
			name: "Dashboard",
			route: "/account/properties/portfolio",
			icon: AllPropertiesIcon,
			activeIcon: AllPropertiesIcon,
			displayName: null,
			mobileOnly: true,
		},
	};
};

export function getNeedsAttentionTitle(
	propertyDetails: Partial<
		Pick<
			PropertyDetails,
			"verified_owner" | "legal_owner" | "auth_company" | "auth_company_type"
		>
	>,
	appealDetails: Partial<Pick<PropertyDetailsAppeal, "status">> | undefined,
	reasons:
		| PropertyDetails["needs_attention_reason"]
		| PropertyDetails["needs_attention_reason"][number]
): string {
	if (Array.isArray(reasons) && reasons.length > 1) {
		return "Information Needed";
	}

	const reasonName = Array.isArray(reasons) ? reasons[0]?.name : reasons.name;

	if (
		propertyDetails.verified_owner &&
		(reasonName === "Wrong Owner" || reasonName === "Cannot Verify Owner")
	) {
		return "Potential Ownership Change";
	}

	switch (reasonName) {
		case "Need Closing Docs":
			return "Closing Disclosure Needed";
		case "Notice of Value":
			return "Notice of Value Needed";
		case "Need Income Info":
			return "Income Information Needed";
		case "Need Condition Info":
			return "Condition Information Needed";
		case "Wrong Owner":
		case "Authorization Expired":
			return "New Signature Required";
		case "Cannot Verify Owner":
			return "Owner Info Mismatch";
		case "Cannot Verify Owner Entity": //deprecated
			return "Verification Information Needed";
		case "Entity Docs Needed (Trust / Estate / Corp)":
			const entityType = getEntityType(propertyDetails);

			if (entityType === "Trust") {
				return "Trust Documents Needed";
			} else if (entityType === "Estate") {
				return "Estate Documents Needed";
			} else {
				return "Entity Documents Needed";
			}
		case "Update County Ownership Record":
			return "County Records Update Needed";
		case "Arbitration":
			if (
				appealDetails?.status?.toLowerCase().includes("litigation") ||
				appealDetails?.status?.toLowerCase().includes("escalation")
			) {
				return appealDetails.status;
			}

			return "Escalation Recommended";
		default:
			return "Information Needed";
	}
}

export const getNeedsAttentionBody = (
	reason: PropertyDetailsAppeal["needs_attention_reason"][number],
	property: PropertyDetails
): string | JSX.Element | undefined => {
	const highlightIfDocsFailedReview = (el: JSX.Element) => {
		if (reason.documentsFailedReview) {
			return <span className="terracotta bold">{el}</span>;
		}

		return el;
	};

	if (
		property.verified_owner &&
		(reason.name === "Wrong Owner" || reason.name === "Cannot Verify Owner")
	) {
		return (
			<>
				We are having trouble verifying ownership of{" "}
				<span className="no-translate">
					{[property.address, property.address2].join(" ")}
				</span>{" "}
				due to a change in the county’s recorded owner. This commonly happens
				when a property has been recently sold, ownership is transferred (e.g.
				to a trust), or when an owner’s name is adjusted. If we do not have this
				issue resolved quickly, we won't be able to appeal your tax assessment
				this year.
			</>
		);
	}

	switch (reason.name) {
		case "Need Closing Docs":
			if (reason.documentsFailedReview) {
				return `After review, a member of our team found issues with the Closing Disclosure documents you previously uploaded. Please upload the signature page of your Closing Disclosure document with the purchase price and signature so we can continue processing this appeal. We may not be able to continue with your appeal if the purchase price and signature are not visible.`;
			}
			return (
				<>
					Our records indicate you recently purchased the property at{" "}
					<span className="no-translate">{property.address}</span>. Please
					upload the completed Closing Disclosure so we can continue processing
					your appeal.
				</>
			);
		case "Need Income Info":
			// if (reason.documentsFailedReview) {
			// 	return <span>After review, a member of our team found issues with the Income Documents you previously uploaded. Please upload {property.tax_year - 1} Rent Roll with lease amount by Unit, by Month, and a {property.tax_year - 1} Profit & Loss Statement. We’ve made <Link href="/templates" target="_blank">templates</Link> that are easy to use, and that usually take just a few minutes to complete using information you probably have on hand already. Visit the <Link href="/templates" target="_blank">Template page</Link> on our website for more information. We may not be able to continue with your appeal without the correct documentation.</span>; //prettier-ignore
			// }
			return (
				<div>
					<p className="sm">
						Our records indicate this may be a rental property. Tax assessors
						require a{" "}
						{highlightIfDocsFailedReview(
							<>
								<span className="bold">Profit & Loss (P&L) Statement</span> and{" "}
								<span className="bold">Rent Roll</span>
							</>
						)}{" "}
						from the prior year to review cases for rental properties. Providing
						this information will also help us build an income-valuation model
						and a stronger case for a potential property tax&nbsp;reduction.
					</p>
				</div>
			);
		case "Need Condition Info":
			// if (reason.documentsFailedReview) {
			// 	return <span>After review, a member of our team found issues with the Condition Information you previously provided. Repair quotes or invoices indicating evidence of property damage or deferred maintenance as of January 1st, {property.tax_year} is one of the most effective ways to reduce your property taxes. Read more about helpful evidence <Link href="/blog/property-damage-repairs-and-tax-reductions-what-you-need-to-know-7" target="_blank">here</Link>.</span> //prettier-ignore
			// }
			return (
				<div>
					<p className="sm">
						Our records indicate this property may be in need of major repairs.
						If known damage or deferred maintenance currently exist, or existed
						as of January 1,{" "}
						<span className="no-translate">{property.tax_year}, </span>
						{highlightIfDocsFailedReview(
							<span>
								please provide any quotes, invoices, or insurance estimates for
								any necessary repairs
							</span>
						)}
						.
					</p>
				</div>
			);
		case "Wrong Owner":
			return (
				<>
					We are having trouble verifying ownership of{" "}
					<span className="no-translate">{property.address}</span> due to a
					mismatch in the property owner information that was provided during
					sign up and the county records. Please have the county owner of record
					re-sign to provide authorization to appeal, or have the county
					property record updated to match the name provided during sign up. If
					we do not have this issue resolved quickly, we won't be able to
					represent you for an appeal this year.
				</>
			);
		case "Cannot Verify Owner":
		case "Cannot Verify Owner Entity": //deprecated
			if (reason.documentsFailedReview) {
				let text =
					"After review, a member of our team found issues with the Owner Verification you previously uploaded. Please upload proof of ownership like a notice of value or a closing document with the purchase price and a signature clearly visible or an assessment notice. We will not be able to continue with your appeal without the correct documentation.";
				return text;
			}
			return (
				<>
					We are having trouble verifying ownership of{" "}
					<span className="no-translate">{property.address}</span> due to a
					mismatch in the property owner information that was provided during
					sign up and the county records. Please upload proof of ownership such
					as a notice of assessed value or a closing disclosure. If we do not
					have this issue resolved quickly, we won't be able to appeal your tax
					assessment this year.
				</>
			);
		case "Authorization Expired":
			return "As a routine part of serving as your property tax agent, we are often required to obtain an updated signature from the property owner. Please click the link below to update your Agent Authorization, which is the same form as when you initially signed up with us.";
		case "Notice of Value":
			if (property.state === "WA") {
				return (
					<>
						You indicated you received a{" "}
						<span className="no-translate">{property.tax_year}</span> Notice of
						Value for the property at{" "}
						<span className="no-translate">{property.address}</span>. Washington
						state requires that a tax appeal be filed within 30 days of
						receiving your notice of value. Please upload a copy of this Notice
						of Value or verify you did not receive one. Otherwise, we may not be
						able to appeal your tax assessment this year.
					</>
				);
			}
			return (
				<>
					We are having trouble verifying ownership of{" "}
					<span className="no-translate">{property.address}</span> because your
					property details are not publicly accessible in the county records.
					Please upload an assessment notice or another document showing proof
					of ownership. If we do not have this issue resolved quickly, we won't
					be able to appeal your tax assessment this year.
				</>
			);
		case "Entity Docs Needed (Trust / Estate / Corp)":
			const entityType = getEntityType(property);

			if (entityType === "Trust") {
				return (
					<>
						In order to guarantee we are properly authorized as your
						representative, we need additional documentation to establish your
						authority to represent the Trust listed in county property records,
						<span className="no-translate">
							{property.legal_owner ?? property.auth_company}
						</span>
						. We may not be able to continue with your appeal without the
						correct documentation.
					</>
				);
			} else if (entityType === "Estate") {
				return (
					<>
						In order to guarantee we are properly authorized as your
						representative, we need additional documentation to establish your
						authority to represent the Estate listed in the county property
						records,
						<span className="no-translate">
							{property.legal_owner ?? property.auth_company}
						</span>
						. Please provide a copy of a document proving authorization to
						represent the estate. We may not be able to continue with your
						appeal without the correct documentation.
					</>
				);
			} else {
				return (
					<>
						In order to guarantee we are properly authorized as your
						representative, we need additional documentation to establish your
						authority to represent the entity listed in county property records,{" "}
						<span className="no-translate">
							{property.legal_owner ?? property.auth_company}
						</span>
						. We may not be able to continue with your appeal without the
						correct documentation.
					</>
				);
			}
		case "Update County Ownership Record":
			return "In order to ensure we are properly authorized as your representative, please contact your county to request an update to ensure that you are reflected as the owner of record. The county will need to have the correct owner of record on file in order for us to represent you and attend your appeal hearing. Please let us know when you have contacted the county.";
		case "Arbitration":
			return "We recommend filing arbitration to get your assessment further reduced.";
	}
};

const ConsumerVsCountyRecordsCard = ({
	property,
}: {
	property: PropertyDetails;
}) => {
	const shouldAddressBeHighlighted = useMemo(() => {
		if (!property.raw_address) return false;
		const countyAddress = (
			property.address +
			(property.address2 ? " " + property.address2 : "") +
			", " +
			property.city +
			", " +
			property.state +
			" " +
			property.zip_code
		).toLowerCase();

		if (countyAddress !== property.raw_address.toLowerCase()) {
			return true;
		}
		return false;
	}, [
		property.address,
		property.address2,
		property.city,
		property.raw_address,
		property.state,
		property.zip_code,
	]);

	const shouldAPNBeHighlighted = useMemo(() => {
		if (!property.raw_apn) return false;
		if (property.raw_apn !== property.apn) {
			return true;
		}
		return false;
	}, [property.apn, property.raw_apn]);

	return (
		<div className="needs-attention-consumer-vs-county-records-cards">
			<div className="needs-attention-consumer-vs-county-records-single-card">
				<p className="sm bold uppercase">PROVIDED DURING SIGNUP</p>
				<Label>Owner Name</Label>
				<div>
					<p className="sm bold needs-attention-consumer-vs-county-records-highlight no-translate">
						{property.auth_company
							? property.auth_company
							: property.auth_first_name + " " + property.auth_last_name}
					</p>
				</div>
				<Label>Address</Label>
				<p
					className={
						"sm bold no-translate" +
						(shouldAddressBeHighlighted
							? " needs-attention-consumer-vs-county-records-highlight"
							: "")
					}>
					{property.raw_address}
				</p>
				<Label>Parcel #</Label>
				<p
					className={
						"sm bold no-translate" +
						(shouldAPNBeHighlighted
							? " needs-attention-consumer-vs-county-records-highlight"
							: "")
					}>
					{property.raw_apn ?? "-"}
				</p>
			</div>
			<div className="needs-attention-consumer-vs-county-records-single-card">
				<p className="sm bold uppercase">
					<span className="no-translate">{property.county ?? "YOUR"}</span>{" "}
					County Records
				</p>
				<Label>Owner Name</Label>
				<div>
					<p className="sm bold needs-attention-consumer-vs-county-records-highlight">
						{property.legal_owner ? (
							<span className="no-translate">{property.legal_owner}</span>
						) : (
							"Not Available"
						)}
					</p>
				</div>
				<Label>Address</Label>
				<p
					className={
						"sm bold no-translate" +
						(shouldAddressBeHighlighted
							? " needs-attention-consumer-vs-county-records-highlight"
							: "")
					}>
					{property.address +
						(property.address2 ? " " + property.address2 : "") +
						", " +
						property.city +
						", " +
						property.state +
						" " +
						property.zip_code}
				</p>
				<Label>Parcel #</Label>
				<p
					className={
						"sm bold no-translate" +
						(shouldAPNBeHighlighted
							? " needs-attention-consumer-vs-county-records-highlight"
							: "")
					}>
					{property.apn ?? "-"}
				</p>
			</div>
		</div>
	);
};

export const getNeedsAttentionReadMore = (
	reason: PropertyDetails["needs_attention_reason"][number],
	property: PropertyDetails
): string | JSX.Element | undefined => {
	const highlightBoxIfDocsFailedReview = (el: JSX.Element) => {
		if (reason.documentsFailedReview) {
			return <div className="needs-attention-read-more-highlighted">{el}</div>;
		}

		return el;
	};

	if (
		(reason.name === "Wrong Owner" || reason.name === "Cannot Verify Owner") &&
		property.verified_owner
	) {
		return (
			<div className="needs-attention-read-more-content">
				<div className="needs-attention-consumer-vs-county-records-cards">
					<div className="needs-attention-consumer-vs-county-records-single-card">
						<p className="sm bold uppercase">Previous Ownership</p>
						<Label>Owner Name</Label>
						<div>
							<p className="sm bold needs-attention-consumer-vs-county-records-highlight needs-attention-consumer-vs-county-records-highlight-only-ownername no-translate">
								{property.auth_company
									? property.auth_company
									: property.auth_first_name + " " + property.auth_last_name}
							</p>
						</div>
					</div>
					<div className="needs-attention-consumer-vs-county-records-single-card">
						<p className="sm bold uppercase">New Ownership</p>
						<Label>Owner Name</Label>
						<div>
							<p className="sm bold needs-attention-consumer-vs-county-records-highlight needs-attention-consumer-vs-county-records-highlight-only-ownername">
								{property.legal_owner ? (
									<span className="no-translate">{property.legal_owner}</span>
								) : (
									"Not Available"
								)}
							</p>
						</div>
					</div>
				</div>
				<p className="sm">
					<b>Ownership Transfer or Adjustment</b>
				</p>
				<p className="sm mt-1">
					Common situations where a property’s ownership has been transferred or
					adjusted include:
				</p>
				<ul style={{ listStylePosition: "inside" }}>
					<li className="sm">Ownership transferred to an estate or trust</li>
					<li className="sm">Owner’s entity name has been changed</li>
					<li className="sm">A spouse has been added as an owner</li>
					<li className="sm">The owner’s legal name has changed</li>
				</ul>
				<p className="sm mt-1">
					In any of the above cases, please update the authorization to match
					the new owner on record.
				</p>
			</div>
		);
	}

	switch (reason.name) {
		case "Notice of Value":
			const noticeOfValueAdditionalCopy = (
				<>
					<ConsumerVsCountyRecordsCard property={property} />
					<p className="sm">
						In some counties, a property owner can submit a request for
						confidentiality. When granted, a property’s details, including
						ownership information, are not on the public record. While we can
						still represent you and handle your property tax appeal case while
						honoring confidentiality, we need to verify ownership manually
						through your tax assessment notice.
					</p>
				</>
			);
			const noticeOfValueDefault = (
				<>
					<p className="sm bold">A notice of value must include:</p>
					{highlightBoxIfDocsFailedReview(
						<ul>
							<li className="sm">The current tax year</li>
							<li className="sm">The property owner name</li>
							<li className="sm">The address (or situs)</li>
						</ul>
					)}
				</>
			);

			if (property.state === "WA") {
				return (
					<div className="needs-attention-read-more-content">
						{noticeOfValueDefault}
					</div>
				);
			}
			return (
				<div className="needs-attention-read-more-content">
					{noticeOfValueAdditionalCopy}
					{noticeOfValueDefault}
				</div>
			);

		case "Need Closing Docs":
			// prettier-ignore
			return <div className="needs-attention-read-more-content">
				<p className="sm">Our tax specialists are currently gathering information and data to support a property tax assessment reduction for your property. The information you provide will be used as evidence of your purchase price, which strengthens our case for earning you a property tax reduction. Without it, it may be difficult or even impossible to earn you a reduction, since the assessor can see the property was recently purchased.</p>
				<p className="sm bold">A closing disclosure is a 5 page document covering the important aspects of a mortgage loan and must include the:</p>
				{highlightBoxIfDocsFailedReview(
					<ul>
						<li className="sm">Property owner address</li>
						<li className="sm">Sale price</li>
						<li className="sm">Realtor details: Seller and buyer information</li>
						<li className="sm">Real estate commission</li>
						<li className="sm">Signed signature page</li>
					</ul>
				)}
				<p className="sm">If the property located at <span className="no-translate">{property.address}</span> is not a recent purchase, please let us know.</p>
			</div>
		case "Need Income Info":
			// prettier-ignore
			return <div className="needs-attention-read-more-content">
				<p className="sm">Additional information is required to complete this property tax appeal.</p>
				<p className="sm">At your earliest convenience, please upload a {property.tax_year - 1} Profit & Loss Statement, and {property.tax_year - 1} Rent Roll by Unit, by Month. Note that we may not be able to continue this appeal without these documents.</p>
				<p className="sm">Don’t have this information available? No problem! We’ve made <Link href="/templates" target="_blank">templates</Link> that are easy to use, and that usually take just a few minutes to complete using information you may have on-hand. Visit our <Link href="/templates" target="_blank">Template page</Link> for more&nbsp;information.</p>
				<p className="sm">If the property located at <span className="no-translate">{property.address}</span> is owner-occupied or not for rent, please select the “Not For Rent” option&nbsp;below.</p></div>
		case "Need Condition Info":
			return (
				<div className="needs-attention-read-more-content">
					<p className="sm">
						Evidence of property damage or deferred maintenance is one of the
						most effective ways to reduce your property taxes.
					</p>
					<p className="sm">
						Completing the property survey is a great way to provide the
						additional evidence that may be helpful in your case. If you have
						already completed the survey, we may need additional documentation
						on top of what you have provided to effectively reduce your property
						tax bill.
					</p>
				</div>
			);
		case "Wrong Owner":
			return (
				<div className="needs-attention-read-more-content">
					<ConsumerVsCountyRecordsCard property={property} />
					<p className="sm bold">Owned by Family Member</p>
					<p className="sm">
						Often the spouse, partner, or a deceased family member is listed by
						the county as the owner of record. We are required to obtain
						authorization from the listed owner to appeal. Have the property
						owner listed by the county use the link below to finish the signup
						process again. If the owner of record is a deceased family member,
						please indicate this by noting “Estate of [name]” in the entity
						field when reauthorizing.
					</p>
					<p className="sm bold">Owned by Trust, LLC, or Entity</p>
					<p className="sm">
						If your property is owned by a Trust, LLC, or other entity, please
						re-sign and include the entity and authorized representative’s
						title. Some counties may require additional proof you are authorized
						to represent the entity.
					</p>
				</div>
			);
		case "Cannot Verify Owner":
			return (
				<div className="needs-attention-read-more-content">
					<ConsumerVsCountyRecordsCard property={property} />
					<p className="sm bold">Ownership or Address Mismatch</p>
					<p className="sm">
						If this property was not recently purchased, uploading a copy of
						your assessment notice helps us match the property you signed up
						with the county records.{" "}
						<span className="bold">A notice of value must include the:</span>
					</p>
					{highlightBoxIfDocsFailedReview(
						<ul>
							<li className="sm">Current tax year</li>
							<li className="sm">Property owner name</li>
							<li className="sm">Property address or situs</li>
						</ul>
					)}
					<p className="sm bold">Recent Purchase</p>
					<p className="sm">
						If you recently purchased this property, supporting documents like a
						closing disclosure are helpful for verification and in achieving a
						successful appeal. Also, please contact your county assessor and
						instruct them to update you are as the owner of record. The county
						will need to have the correct owner of record on file in order for
						us to represent you and attend your appeal hearing.{" "}
						<span className="bold">
							A closing disclosure is a 5 page document covering the important
							aspects of a mortgage loan and must include the:
						</span>
					</p>
					{highlightBoxIfDocsFailedReview(
						<ul>
							<li className="sm">Property owner address</li>
							<li className="sm">Sale Price</li>
							<li className="sm">
								Realtor details: Seller and buyer information
							</li>
							<li className="sm">Real estate commission</li>
							<li className="sm">Signed signature page</li>
						</ul>
					)}
				</div>
			);
		case "Entity Docs Needed (Trust / Estate / Corp)":
			const entityType = getEntityType(property);

			if (entityType === "Trust") {
				return (
					<div>
						<p className="sm">The document must include the following:</p>
						{highlightBoxIfDocsFailedReview(
							<ul
								style={{ listStylePosition: "inside", padding: "8px 16px 0" }}>
								<li className="sm">
									Trust name (as reflected in the county owner of record)
								</li>
								<li className="sm">Authorized representative’s name</li>
								<li className="sm">
									Authorized representative’s title such as Trustor or Trustee
								</li>
								<li className="sm">Notary Stamp</li>
								<li className="sm">Completed signature fields (if visible)</li>
							</ul>
						)}
					</div>
				);
			} else if (entityType === "Estate") {
				return (
					<div>
						<p className="sm">The document must include the following:</p>
						{highlightBoxIfDocsFailedReview(
							<ul
								style={{ listStylePosition: "inside", padding: "8px 16px 0" }}>
								<li className="sm">
									Estate name (as reflected in the county owner of record)
								</li>
								<li className="sm">Authorized representative’s name</li>
								<li className="sm">
									Authorized representative’s title such as estate
									administrator, executor, or personal representative
								</li>
								<li className="sm">Completed signature fields (if visible)</li>
							</ul>
						)}
					</div>
				);
			} else {
				return (
					<div className="needs-attention-read-more-content">
						<p className="sm">Acceptable documents include a:</p>
						{highlightBoxIfDocsFailedReview(
							<ul>
								<li className="sm">Certificate of Formation</li>
								<li className="sm">Tax Franchise Public Information Report</li>
								<li className="sm">Corporation Representative Letter</li>
							</ul>
						)}
						<p className="sm mt-1">The document must include the following:</p>
						{highlightBoxIfDocsFailedReview(
							<ul>
								<li className="sm">
									Entity name (as reflected in the county owner of record)
								</li>
								<li className="sm">Authorized representative’s name</li>
								<li className="sm">
									Authorized representative’s title/position within the entity
								</li>
							</ul>
						)}
						<p className="sm">The document does not need to be notarized.</p>
					</div>
				);
			}
	}
};

export const sortProperties = (_a: PropertyDetails, _b: PropertyDetails) => {
	const a = _a.address ?? _a.apn ?? _a.county;
	const b = _b.address ?? _b.apn ?? _b.county;

	const strip = (str: string) =>
		str
			.trim()
			.split(" ")
			.slice(1)
			.filter(s => s.length > 2)
			.join(" ")
			.toUpperCase();

	const stripA = strip(a);
	const stripB = strip(b);
	if (_a.needs_attention) return -1;
	if (_b.needs_attention) return 1;

	return stripB === stripA ? (b < a ? 1 : -1) : stripB < stripA ? 1 : -1;
};

export const sortPropertiesForTaxYear = (taxYear: number) => {
	return (_a: PropertyDetails, _b: PropertyDetails) => {
		const appealForTaxYearA = _a.appeals.find(
			appeal => appeal.tax_year === taxYear
		);
		const appealForTaxYearB = _b.appeals.find(
			appeal => appeal.tax_year === taxYear
		);
		const a = _a.address;
		const b = _b.address;

		const strip = (str: string) =>
			str
				.trim()
				.split(" ")
				.slice(1)
				.filter(s => s.length > 2)
				.join(" ")
				.toUpperCase();

		const stripA = strip(a);
		const stripB = strip(b);
		if (appealForTaxYearA && appealForTaxYearB) {
			if (appealForTaxYearA?.needs_attention) return -1;
			if (appealForTaxYearB?.needs_attention) return 1;
		}
		return stripB === stripA ? (b < a ? 1 : -1) : stripB < stripA ? 1 : -1;
	};
};

export const SHOULD_SEE_NEEDS_ATTN_MODAL: NeedsAttentionReasons[] = [
	"Need Income Info",
	"Need Closing Docs",
	"Notice of Value",
	"Need Condition Info",
	"Wrong Owner",
	"Cannot Verify Owner",
	"Cannot Verify Owner Entity",
	"Authorization Expired",
	"Entity Docs Needed (Trust / Estate / Corp)",
	"Update County Ownership Record",
];

export const getMultiPropCTA = (
	property: PropertyDetails
): {
	href: string;
	copy: string;
	component: () => JSX.Element;
} => {
	const defaultHref = `/account/properties/${property.id}`;
	const defaultCopy = "View Property";
	const defaultComponent = () => {
		return (
			<QuickLink size="large" href={defaultHref}>
				{defaultCopy}
			</QuickLink>
		);
	};

	if (property.needs_attention) {
		if (
			(property.reauthorization_session &&
				property.needs_attention_reason.length === 1) ||
			property.needs_attention_reason?.some(r =>
				SHOULD_SEE_NEEDS_ATTN_MODAL.includes(r.name)
			)
		) {
			const copy = "Learn More";
			return {
				href: defaultHref,
				copy,
				component: () => (
					<QuickLink size="large" href={defaultHref}>
						{copy}
					</QuickLink>
				),
			};
		}
	}

	let href = defaultHref,
		copy: string;

	switch (property.status) {
		case "Escalation Recommended":
		case "Litigation Recommended":
		case "Monitoring":
		case "Processing":
			copy = "Learn More";
			return {
				href,
				copy,
				component: () => (
					<QuickLink size="large" href={href}>
						{copy}
					</QuickLink>
				),
			};
		default:
			return {
				href: defaultHref,
				copy: defaultCopy,
				component: defaultComponent,
			};
	}
};

export const checkIsAppealCanceledBecauseExemptionOnly = (
	property: Pick<PropertyDetails, "appeal_stage" | "archive_reason">
) => {
	return (
		property.appeal_stage === "Archive" &&
		property.archive_reason === constants.EXEMPTION_ONLY_ARCHIVE_REASON
	);
};

export const checkCanCancelProperty = (
	property: Pick<PropertyDetails, "appeal_stage" | "owl_deadline">
): boolean => {
	if (property.appeal_stage === "Archive") return false;

	if (!!property.owl_deadline) {
		if (moment().isAfter(moment(property?.owl_deadline).subtract(2, "month")))
			return false;
	}

	switch (property.appeal_stage) {
		case "Signed":
		case "Verified":
		case "Staged":
		case "Evaluated":
		case "On Hold":
			return true;
		case "Filed":
		case "Won":
		case "Lost":
		case "Reject":
		case "Archive":
			return false;
	}

	return false;
};

export const checkCanPauseAutoAppeal = (
	property: Pick<
		PropertyDetails,
		"auto_appeal" | "archive_reason" | "appeal_stage"
	>
) => {
	return (
		!!property.auto_appeal &&
		!checkIsAppealCanceledBecauseExemptionOnly(property)
	);
};

export const checkCanActivateAutoAppeal = (
	property: Pick<
		PropertyDetails,
		| "auto_appeal"
		| "accepting_appeals"
		| "appeal_stage"
		| "archive_reason"
		| "state"
	>
) => {
	return (
		!property.auto_appeal &&
		!!property.accepting_appeals &&
		!checkIsAppealCanceledBecauseExemptionOnly(property) &&
		property?.state !== "NY"
	);
};

export const checkCanMarkAsSold = (
	property: Pick<PropertyDetails, "customer_mark_as_sold_at">
) => {
	return !property.customer_mark_as_sold_at;
};

export const checkCanActivateCurrentAppeal = (
	property: Pick<
		PropertyDetails,
		| "owl_deadline"
		| "accepting_appeals"
		| "appeal_stage"
		| "archive_reason"
		| "tax_year"
	>
) => {
	return (
		!!property.owl_deadline &&
		!!property.accepting_appeals &&
		moment.utc().isBefore(moment.utc(property.owl_deadline)) &&
		!checkIsAppealCanceledBecauseExemptionOnly(property) &&
		property.tax_year !== new Date().getFullYear()
	);
};

export const getExemptionStatusTitle = (
	property: PropertyDetails,
	allProperties: PropertyDetails[]
) => {
	const allPropertiesWithActiveExemptions = allProperties
		.filter(property => {
			return (
				property.exemption_status &&
				!["Archive", "Started"].includes(property.exemption_status)
			);
		})
		.map(property => property.id);

	// Deactivate exemptions service for customers who have indicated that another property is their primary residence
	if (
		allPropertiesWithActiveExemptions?.length &&
		!allPropertiesWithActiveExemptions?.includes(property.id)
	) {
		return "Already Has Homestead on Another Property";
	}

	if (!!property.is_homestead && !property?.exemption)
		return "Already Has Homestead";

	if (
		!property.is_homestead &&
		!!property.homestead_fell_off_at &&
		moment(property.homestead_fell_off_at).isBefore(
			moment().subtract(30, "day")
		) &&
		!property.question_purchased &&
		[null, "Active"].includes(property.exemption_status)
	) {
		return "Homestead Fell Off";
	}

	if (property?.auth_company_type === "LLC" && property?.state === "TX") {
		return "Not Qualified";
	}

	if (!!property.exemption?.needs_attention_reason) {
		return "Processing";
	}

	switch (property.exemption_status) {
		case "Started":
			return "Information Needed";
		case "Signed":
		case "Verified":
		case "Ready to File":
		case "On Hold":
			return "Processing";
		case "Filed":
			return "Filed";
		case "Active":
			return "Active";
		case "Denied":
			return "Denied";
		case "Archive":
			switch (property.exemption?.archive_reason) {
				case "DIY":
				case "Already Has Homestead":
					return "Active";
				case "Not Primary Residence":
					return "Not Qualified";
				case "Exemption Type Not Supported":
					return "Exemption Type Not Supported";
				case "Will Not Provide Homeowner Benefit Portal Credentials":
					return "Will Not Provide Homeowner Benefit Portal Credentials";
				case "Research Survey Completed":
					return states_with_viable_exemptions.includes(
						property.state as (typeof states_with_viable_exemptions)[number]
					)
						? "Processing"
						: "Monitoring Active";
			}

			return "Not Qualified";
	}

	return property.exemption_status;
};

export const isEnterprise = (userData?: UserData): boolean => {
	return (userData?.num_active_properties ?? 0) > 50;
};

export const multiDashBoardEnabled = (userData?: UserData) => {
	return (
		userData &&
		userData.multi_dashboard_enabled &&
		(userData?.num_active_properties ?? 0) > 9
	);
};

export const getInsuranceStatus = (
	property: PropertyDetails
): InsuranceStatus => {
	// if (
	// 	property.insurance?.insurance_id &&
	// 	property.insurance.quotes.find(q => q.is_policy)
	// ) {
	// 	return "POLICY_ADDED";
	// } else if (
	// 	property.insurance?.insurance_id &&
	// 	property.insurance.quotes_expired
	// ) {
	// 	return "EXPIRED";
	// } else if (
	// 	property.insurance?.insurance_id &&
	// 	!!property.insurance?.quotes?.find(q => q.selected)
	// ) {
	// 	return "QUOTE_SELECTED";
	// } else if (
	// 	property.insurance?.insurance_id &&
	// 	!!property.insurance.quotes_ready_at &&
	// 	!property.insurance.quotes.length
	// ) {
	// 	return "NO_QUOTES";
	// } else if (
	// 	property.insurance?.insurance_id &&
	// 	!!property.insurance?.quotes_ready_at
	// ) {
	// 	return "QUOTES_READY";
	// } else if (
	// 	property.insurance?.insurance_id &&
	// 	!property.insurance?.quotes_ready_at
	// ) {
	// 	return "QUOTES_LOADING";
	// }

	if (property.insurance.quotes.length) {
		return "QUOTES_READY";
	}

	if (property.insurance?.insurance_id) {
		return "ACTIVATED";
	} else if (!property.insurance?.insurance_id) {
		return "BEFORE_SURVEY";
	}

	return "BEFORE_SURVEY";
};

export const getExemptionAverageSavings = (state?: string) => {
	if (state === "TX") {
		return constants.EXEMPTIONS_TX_SAVINGS;
	}

	if (state === "GA") {
		return constants.EXEMPTIONS_GA_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (state === "CA") {
		return constants.EXEMPTIONS_CA_AVERAGE_FIVE_YEAR_SAVINGS;
	}

	if (state === "NY") {
		return constants.EXEMPTIONS_NY_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (state === "IL") {
		return constants.EXEMPTIONS_IL_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (state === "FL") {
		return constants.EXEMPTIONS_FL_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (state === "MI") {
		return constants.EXEMPTIONS_MI_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (state === "PA") {
		return constants.EXEMPTIONS_PA_AVERAGE_FIRST_YEAR_SAVINGS;
	}

	if (
		states_with_viable_exemptions.includes(
			(state ?? "") as (typeof states_with_viable_exemptions)[number]
		)
	) {
		return formatDollar(
			states_with_viable_exemptions_savings[
				state as (typeof states_with_viable_exemptions)[number]
			]
		);
	}

	return null;
};

export const getExemptionAverageSavingsCopy = (state?: string) => {
	if (state === "TX") {
		return "Average Savings";
	}

	if (state === "GA") {
		return "Average First Year Savings";
	}

	if (state === "CA") {
		return "Average Five Year Savings";
	}

	if (state === "NY") {
		return "Average First Year Savings";
	}

	if (state === "IL") {
		return "Average First Year Savings";
	}

	if (state === "FL") {
		return "Average First Year Savings";
	}

	if (state === "MI") {
		return "Average First Year Savings";
	}

	if (state === "PA") {
		return "Average First Year Savings";
	}

	return "Average Savings";
};

export const getDocsFailedReviewReasonBody = ({
	all_reasons,
	filter_by_reason,
	address,
	docs_failed_review_reason,
}: {
	filter_by_reason?: PropertyDetails["needs_attention_reason"][number];
	all_reasons?: PropertyDetails["needs_attention_reason"][number][];
} & Pick<PropertyDetails, "address" | "docs_failed_review_reason">) => {
	const reasons: string[] = [];

	if (
		docs_failed_review_reason?.includes("Closing Docs - Auth Info Mismatch") &&
		(!filter_by_reason || filter_by_reason.name === "Need Closing Docs") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Closing Docs" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The information on the document uploaded does not match the owner name provided during sign up. Please upload a closing disclosure following the provided guidelines.`
		);
	}

	if (
		docs_failed_review_reason?.includes(
			"Closing Docs - Invalid Document Uploaded"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Closing Docs") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Closing Docs" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The document uploaded is not a closing disclosure. Please upload a closing disclosure following the provided guidelines.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Closing Docs - Missing Pages or Signature"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Closing Docs") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Closing Docs" && !r.documentsUnderReview
			))
	) {
		reasons.push(`
			The closing disclosure uploaded is either not the standard 5-6 pages or is missing a signature(s). Please upload a closing disclosure following the provided guidelines.
			`);
	}
	if (
		docs_failed_review_reason?.includes(
			"Closing Docs - Missing Sales Information"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Closing Docs") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Closing Docs" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The closing disclosure uploaded is missing either the sale price, realtor details (seller & buyer information), or real estate commissions. Please upload a closing disclosure following the provided guidelines.`
		);
	}

	if (
		docs_failed_review_reason?.includes("NOV - Auth Info Mismatch") &&
		(!filter_by_reason || filter_by_reason.name === "Notice of Value") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Notice of Value" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The information on the document uploaded does not match the owner name provided during sign up. Please upload a notice of value following the provided guidelines.`
		);
	}
	if (
		docs_failed_review_reason?.includes("NOV - Wrong Doc") &&
		(!filter_by_reason || filter_by_reason.name === "Notice of Value") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Notice of Value" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The document was not sufficient in verifying the ownership of ${address}. Please upload a notice of value following the provided guidelines.`
		);
	}
	if (
		docs_failed_review_reason?.includes("Entity - Authorizer Not Mentioned") &&
		(!filter_by_reason ||
			filter_by_reason.name === "Entity Docs Needed (Trust / Estate / Corp)") &&
		(!all_reasons ||
			all_reasons?.find(
				r =>
					r.name === "Entity Docs Needed (Trust / Estate / Corp)" &&
					!r.documentsUnderReview
			))
	) {
		reasons.push(
			`The name provided during sign up does not match an authorized representative, owner, manager, estate executor, or trustee listed on the document uploaded.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Entity - Wrong Doc / Missing Certificate of Trust"
		) &&
		(!filter_by_reason ||
			filter_by_reason.name === "Entity Docs Needed (Trust / Estate / Corp)") &&
		(!all_reasons ||
			all_reasons?.find(
				r =>
					r.name === "Entity Docs Needed (Trust / Estate / Corp)" &&
					!r.documentsUnderReview
			))
	) {
		reasons.push(
			`The uploaded document does not appear to be a Certificate of Trust. Please upload documentation following the provided guidelines.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Entity - Company or Trust Not Mentioned"
		) &&
		(!filter_by_reason ||
			filter_by_reason.name === "Entity Docs Needed (Trust / Estate / Corp)") &&
		(!all_reasons ||
			all_reasons?.find(
				r =>
					r.name === "Entity Docs Needed (Trust / Estate / Corp)" &&
					!r.documentsUnderReview
			))
	) {
		reasons.push(
			`The entity or trust name provided during sign up is missing from the uploaded document. Please upload documentation following the provided guidelines.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Entity - Stamp or Signature Missing"
		) &&
		(!filter_by_reason ||
			filter_by_reason.name === "Entity Docs Needed (Trust / Estate / Corp)") &&
		(!all_reasons ||
			all_reasons?.find(
				r =>
					r.name === "Entity Docs Needed (Trust / Estate / Corp)" &&
					!r.documentsUnderReview
			))
	) {
		reasons.push(
			`A stamp or signature is missing from the entity document provided. Please ensure both are included when reuploading.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Income Info - Missing Address or Auth / Company Name"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Income Info") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Income Info" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The income information document uploaded is missing required information like the property address and authorization or company name. Please upload documentation following the provided guidelines or templates.`
		);
	}

	if (
		docs_failed_review_reason?.includes(
			"Income Info - Missing Rent Roll Categorization"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Income Info") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Income Info" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The income information document uploaded is missing rent roll categorization i.e. monthly rent. Please upload documentation following the provided guidelines or templates.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Income Info - Missing Income / Rent Roll / Rental Statement"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Income Info") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Income Info" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The income information document uploaded is missing the required information for an income statement, rent roll, or rental statement. Please upload documentation following the provided guidelines or templates.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Income Info - Missing Rental Rev Income / Property Taxes"
		) &&
		(!filter_by_reason || filter_by_reason.name === "Need Income Info") &&
		(!all_reasons ||
			all_reasons?.find(
				r => r.name === "Need Income Info" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The income information document uploaded is missing the total rental revenue income and/or Property Taxes associated with income. Please upload documentation following the provided guidelines or templates.`
		);
	}
	if (
		docs_failed_review_reason?.includes(
			"Cannot Verify - Docs Don't Match County Records"
		) &&
		(!filter_by_reason ||
			filter_by_reason.name === "Update County Ownership Record") &&
		(!all_reasons ||
			all_reasons?.find(
				r =>
					r.name === "Update County Ownership Record" && !r.documentsUnderReview
			))
	) {
		reasons.push(
			`The document uploaded does not correlate with the county property records. Common issues include: missing a parcel number, the authorization name provided during sign up does not match the county records, or you recently purchased your property. If you recently purchased your property, we need proof of purchase such as closing documents, deeds, etc.`
		);
	}
	if (
		docs_failed_review_reason?.includes("Generic - Docs Don't Fit Criteria")
	) {
		reasons.push(
			`The document you provided did not meet the criteria needed. Please review the guidelines and upload again.`
		);
	}

	if (reasons.length > 1) {
		return (
			<ol className="documents-failed-review-reasons">
				{reasons.map((reason, idx) => (
					<li key={idx} className="body-tiny">
						{reason}
					</li>
				))}
			</ol>
		);
	}

	if (reasons.length === 1) {
		return <p className="body-tiny">{reasons[0]}</p>;
	}

	return (
		<p className="body-tiny">
			After review, our team determined that the documents you provided did not
			meet the criteria needed. Please review the document guidelines below.
		</p>
	);
};

export const isPropertyNonGeo = (property: PropertyDetails) => {
	return property.available_services.every(
		as => !["appeal", "exemption"].includes(as)
	);
};

export const allPropertiesAreNonGeo = (properties: PropertyDetails[]) => {
	return properties.every(isPropertyNonGeo);
};
