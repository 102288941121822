import React, { useEffect, useState } from "react";
import client from "utils/client";
import { usePropertyContext } from "../../context";
import ConciergeOptionTab from "../../Concierge/ConciergeOptionTab";
import NoConciergeOptionCard from "../../Concierge/NoConciergeOptionCard";
import ConciergeOptionCard from "../../Concierge/ConciergeOptionCard";
import ConciergeProOptionCard from "../../Concierge/ConciergeProOptionCard";
import { useAuthContext } from "context/AuthContext";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";

const ConciergeOptions: React.FC<{
	showSkip?: boolean;
	overrideNext?: () => void;
}> = ({ showSkip = true, overrideNext }) => {
	const { userData } = useAuthContext();
	const { details, send, next } = usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const [billingCycle, setBillingCycle] = useState("annually");

	const trackEvent = useEventTracker();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.Concierge_onboarding_upgrade_viewed,
			data: {
				CustomerId: userData?.id,
			},
		});
	}, []);

	const handleSkip = (action: string) => {
		trackEvent({
			eventName: constants.EVENTS.Concierge_onboarding_upgrade_modal_action,
			data: {
				CustomerId: userData?.id,
				action: action,
			},
		});
		next();
	};

	const handleSubmit = async (tmpValue: string) => {
		const finalConciergeOption = tmpValue + "-" + billingCycle;
		if (tmpValue) {
			send({
				type: "update_context",
				context: { account_tier: finalConciergeOption },
			});
			trackEvent({
				eventName: constants.EVENTS.Concierge_onboarding_upgrade_modal_action,
				data: {
					CustomerId: userData?.id,
					action: finalConciergeOption,
				},
			});
		}
		try {
			setSubmitting(true);
			setError(false);
			await client.upgradeAccountToConcierge({
				accountTier: finalConciergeOption,
			});
			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const updateBillingCycle = (cycle: string) => {
		setBillingCycle(cycle);
	};

	const onOptionSelect = (option: string) => {
		handleSubmit(option);
	};

	return (
		<div className="concierge-options-wrapper">
			<ConciergeOptionTab />
			<div className="concierge-options-cards">
				<NoConciergeOptionCard
					contingencyPercentage={
						details?.contingency ? details?.contingency : 0
					}
					onOptionSelect={() => handleSkip("basic")}
				/>
				<ConciergeOptionCard
					billingCycle={billingCycle}
					contingencyPercentage={
						details?.contingency ? details?.contingency : 0
					}
					onOptionSelect={onOptionSelect}
				/>
				<ConciergeProOptionCard
					billingCycle={billingCycle}
					contingencyPercentage={
						details?.contingency ? details?.contingency : 0
					}
					onOptionSelect={onOptionSelect}
				/>
			</div>
			<div className="skip-wrapper">
				<p className="large" onClick={() => handleSkip("skip")}>
					Not Right Now
				</p>
			</div>
		</div>
	);
};

export default ConciergeOptions;
