import { useEffect, useState } from "react";
import { useAuthContext } from "context/AuthContext";
import PropertyModal from "../Modal";
import { ConciergeContextProvider, useConciergeContext } from "./ConciergePage";
import ConciergePayment from "../Survey/questions/ConciergePayment";
import ConciergeOptionTab from "./ConciergeOptionTab";
import client from "utils/client";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import { useResponsiveContext } from "context/ResponsiveContext";
import { usePathname, useRouter } from "next/navigation";
import { usePropertyContext } from "../context";

export const useConciergePaymentModalFlag = () => {
	const [showConciergePaymentModalFlag, setShowConciergePaymentModalFlag] =
		useState<boolean>(false);

	const { userData } = useAuthContext();
	const pathname = usePathname();

	useEffect(() => {
		if (
			userData?.interested_tier?.includes("concierge") &&
			!userData?.account_tier?.includes("concierge") &&
			!pathname.includes("/concierge") &&
			!showConciergePaymentModalFlag
		) {
			setShowConciergePaymentModalFlag(true);
		} else {
			setShowConciergePaymentModalFlag(false);
		}
	}, [userData?.interested_tier, userData?.account_tier]);

	return { showConciergePaymentModalFlag, setShowConciergePaymentModalFlag };
};

const ConciergePaymentModal = () => {
	const { billingCycle, setBillingCycle } = useConciergeContext();
	const { isMobile } = useResponsiveContext();

	const trackEvent = useEventTracker();

	const { setUserData } = useAuthContext();
	const router = useRouter();
	const { details } = usePropertyContext();

	const handlePaymentSuccess = async () => {
		const userData = await client.getUserData();
		setUserData(userData);
		trackEvent({
			eventName: constants.EVENTS.Concierge_upgrade_payment_action,
			data: {
				CustomerId: userData?.id,
				action: "submit",
			},
		});
	};

	return (
		<PropertyModal
			onAttemptExit={() => {}}
			variant="secondary"
			containerId="concierge-payment-modal-wrapper">
			<ConciergeContextProvider>
				<div className="concierge-payment-modal-content-wrapper">
					<ConciergeOptionTab />
					<div className="concierge-page-payment-wrapper mt-3">
						<ConciergePayment
							handleContinueClick={handlePaymentSuccess}
							showWarningBanner={true}
							defaultExpandedFlag={isMobile ? false : true}
						/>
					</div>
				</div>
			</ConciergeContextProvider>
		</PropertyModal>
	);
};

export default ConciergePaymentModal;
